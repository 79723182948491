import React, { useEffect, useState, useMemo, useCallback } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";
import { withRouter } from "react-router-dom";
import "./storage.scss";
import { withTranslation } from 'react-i18next';
import PaymentInfo from "../../../pages/Orders/components/paymentInfo";

import { getBoxappCustomerBackend } from "src/helpers/boxapp_customer_helper";

const StoragePaymentPanel = (props) => {
  const [wallet, setWallet] = useState();
  const fetchCustomer = useCallback(async () => {
    if (props.order?.customer?.id) {
      const res = await getBoxappCustomerBackend().getCustomer(
        props.order?.customer?.id
      );
      setWallet(res?.customer?.wallet);
    }
  }, [props.order?.customer?.id]);

  useEffect(() => {
    if (!wallet) {
      fetchCustomer();
    }
  }, [wallet]);

  return (
    <Card  >
      {props.titles?.length > 0 &&
        <CardTitle>
          {props.titles?.map((title, i) => {
            return (
              <span key={i}
                className={`font-weight-bold h5 ${i === props.activeStep ? "" : "text-secondary"}`}>
                {props.t("Step " + i) + "-" + title}
                {i < props.titles.length - 1 && <i className="bx bx-right-arrow-alt ml-4 font-size-16 align-middle "></i>}
              </span>
            )

          })}
        </CardTitle>}
      <CardBody>
        <PaymentInfo
          mode={"create"}
          wallet={wallet}
          customerId={props.order?.customer?.id}
          invoice={props.invoice}
          onPaidSuccess={props.onPaidSuccess}
          onSuccess={props.onSuccess}
          onClose={props.onClose}
        />
      </CardBody>
    </Card>
  )

};


export default withRouter(withTranslation()(StoragePaymentPanel));
