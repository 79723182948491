import axios from "axios";
import { BoxappBackend } from "./boxapp_helper";
import { Data, ResponseResult } from "../api/reponseResult";
import { Contract } from "../models/contract";
import { ContractStatus } from "src/util/contractStatus";

class BoxappContractBackend extends BoxappBackend {
  getMasterContracts = async (query: {
    branch_id?: string;
    termination_date_from?: number;
    termination_date_to?: number;
    is_terminated?: boolean;
    deposit_amount_from?: number;
    deposit_amount_to?: number;
    is_move_out?: boolean;
    move_out_date_from?: number;
    move_out_date_to?: number;
    page?: number;
    limit?: number;
  }): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/master-contracts`, query);
    } catch (err) {
      throw err;
    }
  };
  getContracts = async (query: any): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/contracts`, query);
    } catch (err) {
      throw err;
    }
  };
  getMasterContract = async (id: string): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/master-contracts/${id}`);
    } catch (err) {
      throw err;
    }
  };
  updateMasterContract = async (
    id: string,
    param: {
      status?: "ACTIVE" | "TERMINATE" | "CANCEL";
    }
  ): Promise<Data> => {
    try {
      return await this.put(`${this.baseURL}/master-contracts/${id}`, param);
    } catch (err) {
      throw err;
    }
  };
  getContract = async (id: string, query: any): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/contracts/${id}`, query);
    } catch (err) {
      throw err;
    }
  };

  create = async (contract: Contract): Promise<Data> => {
    try {
      return await this.post(`${this.baseURL}/contracts`, contract);
    } catch (err) {
      throw err;
    }
  };
  update = async (
    id: string,
    contract: {
      status?: ContractStatus;
      remarks?: string;
      start_date?: number;
      end_date?: number;
    }
  ): Promise<Data> => {
    try {
      return await this.put(`${this.baseURL}/contracts/${id}`, contract);
    } catch (err) {
      throw err;
    }
  };
  extend = async (contract: Contract): Promise<Data> => {
    try {
      return await this.post(`${this.baseURL}/contracts/extend`, contract);
    } catch (err) {
      throw err;
    }
  };
  end = async (contract: Contract): Promise<Data> => {
    try {
      return await this.post(`${this.baseURL}/contracts/end`, contract);
    } catch (err) {
      throw err;
    }
  };
  uploadAttachment = async (id: string, payload: any): Promise<Data> => {
    try {
      const formData = new FormData();
      formData.append("file", payload.file);
      return await this.upload(`${this.baseURL}/master-contracts/${id}/attachments`, formData);

    } catch (err) {
      throw err;
    }
  };
  getAttachment = async (attachmentID: string): Promise<any> => {
    try {
      return await this.getAttachment(`${this.baseURL}/attachments/${attachmentID}`);
    } catch (err) {
      throw err;
    }
  };

  getAttachments = async (id: string, query: any): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/master-contracts/${id}/attachments`, query);
    } catch (err) {
      throw err;
    }
  };

  getLastOrder = async (
    masterContractId: string,
    query: any
  ): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/master-contracts/${masterContractId}/orders/last`, query);
    } catch (err) {
      throw err;
    }
  };
}

let _BoxappContractBackend: BoxappContractBackend | null = null;

/**
 * Returns the firebase backend
 */
const getBoxappContractBackend = () => {
  if (!_BoxappContractBackend) {
    _BoxappContractBackend = new BoxappContractBackend();
  }
  return _BoxappContractBackend;
};

export { getBoxappContractBackend };
