import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from "react";

import StorageRentModal from "../components/Modal/Storages/StorageRent";
import StorageRentNewOrderModal from "../components/Modal/Storages/StorageRentNewOrder";
import StorageRentNewMasterContract from "../components/Modal/Storages/StorageRentNewMasterContract";
import StorageRentNewContract from "../components/Modal/Storages/StorageRentNewContract";
import StoragePaymentInfoModal from "../components/Modal/Storages/StoragePaymentInfo";
import StorageSearchModal from "../components/Modal/Storages/StorageSearch";
import StorageTerminateModal from "../components/Modal/Storages/StorageTerminate";
import StorageMoveOutModal from "../components/Modal/Storages/StorageMoveOut";
import StorageReleaseModal from "../components/Modal/Storages/StorageRelease";
import StorageCancelModal from "../components/Modal/Storages/StorageCancel";
import StorageCurrentViewModal from "../components/Modal/Storages/StorageCurrentView";
import {
  StorageModalComponent,
  StorageModalProps,
  STORAGE_DIALOG_TYPES,
  StorageSearchModalProps,
  StoragePaymentModalProps,
  StorageRentModalProps,
} from "../components/Modal/StorageModalProps";
import { Storage } from "../models/storage";
import { Category } from "../models/category";
import { Feature } from "../models/feature";
import StorageRentNewDeposit from "src/components/Modal/Storages/StorageRentNewDeposit";

const DIALOG_COMPONENTS = {
  [STORAGE_DIALOG_TYPES.CURRENT_VIEW_MODAL]: StorageCurrentViewModal,
  [STORAGE_DIALOG_TYPES.CANCEL_MODAL]: StorageCancelModal,
  [STORAGE_DIALOG_TYPES.RENT_MODAL]: StorageRentModal,
  [STORAGE_DIALOG_TYPES.RENT_NEW_CONTRACT_MODAL]: StorageRentNewContract,
  [STORAGE_DIALOG_TYPES.RENT_NEW_MASTER_CONTRACT_MODAL]:
    StorageRentNewMasterContract,
  [STORAGE_DIALOG_TYPES.RENT_NEW_DEPOSIT_MODAL]:
    StorageRentNewDeposit,
  [STORAGE_DIALOG_TYPES.RENT_NEW_ORDER_MODAL]: StorageRentNewOrderModal,
  [STORAGE_DIALOG_TYPES.PAYMENT_MODAL]: StoragePaymentInfoModal,
  [STORAGE_DIALOG_TYPES.RELEASE_MODAL]: StorageReleaseModal,
  [STORAGE_DIALOG_TYPES.SEARCH_MODAL]: StorageSearchModal,
  [STORAGE_DIALOG_TYPES.TERMINATE_MODAL]: StorageTerminateModal,
  [STORAGE_DIALOG_TYPES.MOVE_OUT_MODAL]: StorageMoveOutModal,
};

interface StorageContextProps {
  showDialog: (
    modalType: STORAGE_DIALOG_TYPES,
    modalProps:
      | StorageModalProps
      | StorageSearchModalProps
      | StoragePaymentModalProps
      | StorageRentModalProps
  ) => void;
  hideDialog: (modalType: STORAGE_DIALOG_TYPES) => void;
  store: StorageModalComponent;
  filteredCategories: Category[];
  setFilteredCategories: (categories: Category[]) => void;
  filteredFeatures: Feature[];
  setFilteredFeatures: (features: Feature[]) => void;
  contextCategory?: Category;
  setContextCategory: (category?: Category) => void;
  contextStorage?: Storage;
  setContextStorage: (storage?: Storage) => void;
  contextStorages: Storage[];
  setContextStorages: (storages: Storage[]) => void;
  storageStatusList: Storage[];
  setStorageStatusList: (storageStatusList: Storage[]) => void;
  setGridSize: (size: string) => void;
  gridSize: string;
  expiredInDays: number;
  setExpiredInDays: (days: number) => void;
}
const initalState: StorageContextProps = {
  showDialog: (
    modalType: STORAGE_DIALOG_TYPES,
    modalProps:
      | StorageModalProps
      | StorageSearchModalProps
      | StoragePaymentModalProps
      | StorageRentModalProps
  ) => { },
  hideDialog: (modalType: STORAGE_DIALOG_TYPES) => { },
  store: {
    modalType: undefined,
    modalProps: undefined,
    modalShow: false,
  },
  filteredCategories: [],
  setFilteredCategories: (categories: Category[]) => { },
  filteredFeatures: [],
  setFilteredFeatures: (features: Feature[]) => { },
  contextCategory: undefined,
  setContextCategory: (category?: Category) => { },
  contextStorage: undefined,
  setContextStorage: (storage?: Storage) => { },
  contextStorages: [],
  setContextStorages: (storages: Storage[]) => { },
  storageStatusList: [],
  setStorageStatusList: (storageStatusList: Storage[]) => { },
  setGridSize: (size: string) => { },
  gridSize: "xs",
  expiredInDays: 0,
  setExpiredInDays: (days: number) => { },
};

export const StorageContext = createContext(initalState as StorageContextProps);
export const useStorageContext = () => useContext(StorageContext);

const StorageProvider = ({ children }: { children: React.ReactNode }) => {
  //api helper
  const [store, setStore] = useState<StorageModalComponent>();
  const [filteredCategories, setFilteredCategories] = useState<Category[]>([]);
  const [filteredFeatures, setFilteredFeatures] = useState<Feature[]>([]);
  const [storageStatusList, setStorageStatusList] = useState<Storage[]>([]);
  const [contextCategory, setContextCategory] = useState<Category>();
  const [contextStorage, setContextStorage] = useState<Storage>();
  const [contextStorages, setContextStorages] = useState<Storage[]>([]);
  const [expiredInDays, setExpiredInDays] = useState(0);

  const gridSize = useMemo(() => {
    return localStorage.getItem("storage-grid") ?? "xs";
  }, [localStorage]);

  const showDialog = (
    modalType: STORAGE_DIALOG_TYPES,
    modalProps:
      | StorageModalProps
      | StorageSearchModalProps
      | StoragePaymentModalProps
  ) => {
    setStore({
      modalType,
      modalProps,
      modalShow: true,
    });
  };

  const hideDialog = (modalType: STORAGE_DIALOG_TYPES) => {
    setStore({
      modalType: undefined,
      modalProps: undefined,
      modalShow: false,
    });
  };

  const setGridSize = (size: string) => {
    localStorage.setItem("storage-grid", size);
  };

  const renderComponent = (p: StorageModalComponent) => {
    if (!p.modalType) {
      return null;
    }

    const StorageModalComponent =
      DIALOG_COMPONENTS[p.modalType as STORAGE_DIALOG_TYPES];
    if (!StorageModalComponent) {
      return null;
    }

    return (
      <StorageModalComponent
        id="storage-modal"
        {...(p?.modalProps as any)}
        modal={p.modalShow ?? false}
      />
    );
  };

  return (
    <StorageContext.Provider
      value={
        {
          showDialog,
          hideDialog,
          store,
          filteredCategories,
          setFilteredCategories,
          filteredFeatures,
          setFilteredFeatures,
          contextCategory,
          setContextCategory,
          contextStorage,
          setContextStorage,
          contextStorages,
          setContextStorages,
          storageStatusList,
          setStorageStatusList,
          setGridSize,
          gridSize,
          expiredInDays,
          setExpiredInDays,
        } as StorageContextProps
      }
    >
      {store && renderComponent(store)}
      {children}
    </StorageContext.Provider>
  );
};
export default StorageProvider;
