import React, { useEffect, useState, useMemo, useCallback } from "react";

import "./invoiceInfo.scss";
import moment from "moment-timezone";

import {
  Alert,
  FormGroup,
  Row,
  Col,
  Input,
  Button,
  Label,
  Card,
  CardBody,
} from "reactstrap";

// Redux
import SubmissionModal from "../../../components/Modal/SubmissionModal";

import { useTranslation } from "react-i18next";
import { Order } from "src/models/order";
import { Branch } from "src/models/branch";
import AsyncSelect from "react-select/async";
import {
  PaidItemChargeTypeOption,
  optionPaidItemchargeType,
} from "src/util/options";
import PaidItemPeriod from "src/components/Modal/Storages/PaidItemPeriod";
import { ChargeType } from "src/models/chargeType";
import { PaidItem } from "src/models/paidItem";
import { InvoiceType } from "src/models/invoice";
import Decimal from "decimal.js";
import { OrderType } from "src/util/orderType";
import { ChargeMode } from "src/util/chargeMode";

export interface ChargeTypePaidItemProps {
  id?: string;
  order?: Order;
  branch: Branch;
  // storage: Storage;
  // mode: string;
  modal: boolean;
  item?: PaidItem;

  invoiceType: InvoiceType;
  orderType: OrderType;
  onSuccess: (paidItem: PaidItem) => void;
  onFailed: () => void;
  onClose: () => void;
}

export const ChargeTypePaidItem = (props: ChargeTypePaidItemProps) => {
  const { i18n } = useTranslation();
  const [lastOrderPaidItem, setLastOrderPaidItem] = useState<PaidItem>();

  const [errorMessage, setErrorMessage] = useState<any>();
  const [paidType, setPaidType] = useState<PaidItemChargeTypeOption>();
  const [originalPrice, setOriginalPrice] = useState<number>(0);
  const [quantize, setQuantize] = useState<boolean>(false);
  const [unitPrice, setUnitPrice] = useState<number>(0);
  const [unit, setUnit] = useState<string>();
  const [quantityStart, setQuantityStart] = useState<number>(0);
  const [quantityEnd, setQuantityEnd] = useState<number>(0);
  const [defaultQuantity, setDefaultQuantity] = useState<number>(0);
  const [chargeMode, setChargeMode] = useState<ChargeMode>();
  const [roundUp, setRoundUp] = useState<boolean>(false);
  const [roundDown, setRoundDown] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<number | undefined>(
    moment().unix()
  );
  const [endDate, setEndDate] = useState<number>();
  useEffect(() => {
    if (props.item) {
      optionPaidItemchargeType(props.branch?.id)?.then(
        (res: PaidItemChargeTypeOption[]) => {
          setPaidType(
            res?.find((c) => {
              const chargeType = props.item?.item as ChargeType;
              return c.value === chargeType?.charge_type_id;
            })
          );
        }
      );
      setOriginalPrice(Number(props.item?.original_price) ?? 0);
      setQuantize(props.item?.quantize ?? false);
      setUnitPrice(props.item?.unit_price ?? 0);
      setUnit(props.item?.unit);
      setQuantityStart(props.item?.quantity_start ?? 0);
      setQuantityEnd(props.item?.quantity_end ?? 0);
      setDefaultQuantity(props.item?.default_quantity ?? 0);
      setChargeMode(props.item?.charge_mode);
      setStartDate((props.item?.start_date as number) ?? 0);
      setEndDate((props.item?.end_date as number) ?? 0);
    }
  }, [props.item]);

  const onSubmit = async () => {
    const discount_amount = 0;

    if ((originalPrice ?? 0) < 0) {
      setErrorMessage(i18n.t("paid item price is invalid"));
      return;
    }

    setErrorMessage(undefined);
    if (!paidType) {
      setErrorMessage(i18n.t("paid item type is required"));
      return;
    }
    props.onSuccess({
      id: props.item?.id,
      order_id: props.order?.id,
      order_type: props.order?.order_type,
      item_id: paidType?.value,
      item_type: paidType?.charge_type_category?.code,
      item_name: paidType?.charge_name,
      item: {
        id: paidType?.id,
        quantize: paidType?.quantize,
        charge_type_category_id: paidType?.charge_type_category_id,
        charge_type_category: paidType?.charge_type_category,
        charge_name: paidType?.charge_name,
        charge_mode: paidType?.charge_mode,
        unit_price: paidType?.unit_price,
        unit: paidType?.unit,
        default_quantity: paidType?.default_quantity,
        branches: paidType?.branches,
        charge_type_id: paidType?.charge_type_id,
        sort: paidType?.sort,
        branch_id: paidType?.branch_id,
      } as ChargeType,
      start_date: startDate,
      end_date: endDate,
      final_price: originalPrice - discount_amount,
      quantize: quantize,
      unit_price: unitPrice,
      quantity_start: quantityStart ?? defaultQuantity,
      quantity_end: chargeMode === ChargeMode.ACCUMLATIVE ? quantityEnd : undefined,
      default_quantity: defaultQuantity,
      original_price: originalPrice,
      charge_mode: chargeMode,
      unit: unit,
      discount_amount: discount_amount,
      status: "DRAFT",
      
                    sort: props.item?.sort ?? 0,
    });
  };

  const CalRound = (originalPrice: Decimal): Decimal => {
    if (roundUp) {
      return originalPrice.ceil();
    } else if (roundDown) {
      return originalPrice.floor();
    }
    return originalPrice;
  };
  useEffect(() => {
    const price = CalRound(new Decimal(originalPrice));
    setOriginalPrice(price.toNumber());
  }, [roundUp, roundDown]);

  const onClose = () => {
    props.onClose();
  };

  return (
    <SubmissionModal
      id={props.id}
      size={"xl"}
      onSubmit={onSubmit}
      onClose={onClose}
      title={`${i18n.t("Other Charge Items")}`}
      show={props.modal ?? false}
    >
      <Card className="border">
        <CardBody>
          {lastOrderPaidItem && (
            <Alert
              color="warning"
              className="alert-dismissible fade show  mb-4 pt-2 font-size-13"
              role="alert"
            >
              {lastOrderPaidItem?.quantize &&
                lastOrderPaidItem?.charge_mode === ChargeMode.ACCUMLATIVE && (
                  <i className="mdi mdi-calendar-arrow-left">
                    {i18n.t("Last Order Price")}:{" "}
                    {lastOrderPaidItem?.original_price}
                    <br />
                    {i18n.t("Last Quantity End")}:{" "}
                    {lastOrderPaidItem?.quantity_end}
                    {lastOrderPaidItem?.unit}
                    <br />
                    {i18n.t("Last Start Date")}:{" "}
                    {lastOrderPaidItem?.start_date
                      ? moment(
                        (lastOrderPaidItem?.start_date as number) * 1000
                      ).format("YYYY-MM-DD")
                      : "N/A"}
                    <br />
                    {i18n.t("Last End Date")}:{" "}
                    {lastOrderPaidItem?.end_date
                      ? moment(
                        (lastOrderPaidItem?.end_date as number) * 1000
                      ).format("YYYY-MM-DD")
                      : "N/A"}
                  </i>
                )}
              {lastOrderPaidItem?.quantize &&
                lastOrderPaidItem?.charge_mode === ChargeMode.STAND_ALONE && (
                  <i className="mdi mdi-calendar-arrow-left">
                    {i18n.t("Last Order Price")}:{" "}
                    {lastOrderPaidItem?.original_price}
                    <br />
                    {i18n.t("Last Quantity")}: {lastOrderPaidItem?.quantity_end}
                    {lastOrderPaidItem?.unit}
                    <br />
                    {i18n.t("Last Start Date")}:{" "}
                    {lastOrderPaidItem?.start_date
                      ? moment(
                        (lastOrderPaidItem?.start_date as number) * 1000
                      ).format("YYYY-MM-DD")
                      : "N/A"}
                    <br />
                    {i18n.t("Last End Date")}:{" "}
                    {lastOrderPaidItem?.end_date
                      ? moment(
                        (lastOrderPaidItem?.end_date as number) * 1000
                      ).format("YYYY-MM-DD")
                      : "N/A"}
                  </i>
                )}
              {!lastOrderPaidItem?.quantize && (
                <i className="mdi mdi-calendar-arrow-left">
                  {i18n.t("Last Order Price")}:{" "}
                  {lastOrderPaidItem?.original_price}
                  <br />
                  {i18n.t("Last Start Date")}:{" "}
                  {lastOrderPaidItem?.start_date
                    ? moment(
                      (lastOrderPaidItem?.start_date as number) * 1000
                    ).format("YYYY-MM-DD")
                    : "N/A"}
                  <br />
                  {i18n.t("Last End Date")}:{" "}
                  {lastOrderPaidItem?.end_date
                    ? moment(
                      (lastOrderPaidItem?.end_date as number) * 1000
                    ).format("YYYY-MM-DD")
                    : "N/A"}
                </i>
              )}
            </Alert>
          )}
          <Row className="ml-2 mb-1">
            <Col
              xs={
                props.orderType === "Rent" && props.invoiceType === "Payment"
                  ? 10
                  : 4
              }
            >
              <Label>{i18n.t("Paid Type")}</Label>
              <AsyncSelect
                cacheOptions
                defaultOptions
                value={paidType}
                placeholder={i18n.t("Select...")}
                onChange={(c: any) => {
                  console.log(c);
                  setPaidType(c);
                  setQuantize(c?.quantize);
                  setUnit(c?.unit);
                  setUnitPrice(c?.unit_price);
                  setDefaultQuantity(c?.default_quantity);
                  setChargeMode(c?.charge_mode);
                }}
                loadOptions={() => optionPaidItemchargeType(props.branch?.id)}
                classNamePrefix="select-selection"
              />
              <span className="failed">
                {!paidType ? i18n.t("please select a charge type") : ""}
              </span>
            </Col>
            <Col xs="1"></Col>
            <Col xs="1"></Col>
            <Col
              xs={
                props.orderType === "Rent" && props.invoiceType === "Payment"
                  ? 8
                  : 4
              }
            >
              <Label>
                {i18n.t("Price")}
                {props.orderType === "Rent" &&
                  props.invoiceType === "Payment" &&
                  quantize
                  ? `($${unitPrice}/${unit})`
                  : ""}
              </Label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">$</span>
                </div>
                <Input
                  type="number"
                  maxLength={8}
                  name={"paidItemPrice-"}
                  onChange={(e) => {
                    const orginal_price = CalRound(
                      new Decimal(e.target.value ?? 0)
                    ).toNumber();
                    setOriginalPrice(orginal_price);
                  }}
                  value={originalPrice}
                />
              </div>
            </Col>
            {props.orderType === "Rent" && props.invoiceType === "Payment" && (
              <Col xs={4}>
                <Label className="mt-4"> </Label>
                <i
                  className="mdi mdi-chevron-up-circle-outline align-middle ml-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setRoundUp(true);
                    setRoundDown(false);
                  }}
                >
                  {i18n.t("Round Up")}
                </i>
                <i
                  className="mdi mdi-chevron-down-circle-outline align-middle ml-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setRoundUp(false);
                    setRoundDown(true);
                  }}
                >
                  {i18n.t("Round Down")}
                </i>
                <i
                  className="mdi mdi-timeline-text-outline align-middle ml-2 "
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setRoundUp(false);
                    setRoundDown(false);
                  }}
                >{`2 ${i18n.t("digits")}`}</i>
              </Col>
            )}

            {props.orderType === "Rent" &&
              props.invoiceType === "Payment" &&
              quantize && (
                <Col className="mt-2">
                  <Row className="mb-1">
                    {chargeMode === ChargeMode.ACCUMLATIVE && (
                      <Col xs="12">
                        <Label htmlFor="validationRentPeriod">
                          {`${i18n.t("Pay Amount")}${i18n.t(
                            "Total qty"
                          )}:${new Decimal(quantityEnd ?? 0)
                            .minus(quantityStart ?? 0)
                            .toNumber()}${unit}`}
                        </Label>
                      </Col>
                    )}
                    {chargeMode !== ChargeMode.ACCUMLATIVE && (
                      <Col xs="12">
                        <Label htmlFor="validationRentPeriod">
                          {i18n.t("Pay Amount")}
                        </Label>
                      </Col>
                    )}
                    {chargeMode === ChargeMode.ACCUMLATIVE && (
                      <Col xs="12" sm="6">
                        <FormGroup>
                          <Label>{i18n.t("Start")}</Label>
                          <div className="input-group">
                            <Input
                              type="number"
                              maxLength={8}
                              name={"paidItemQtyStart-"}
                              value={(quantityStart ?? 0)
                                .toString()
                                .padStart(6, "0")}
                              onChange={(e) => {
                                const original_price =
                                  Number(e.target.value) ?? 0;
                                if (chargeMode === ChargeMode.ACCUMLATIVE) {
                                  setOriginalPrice(
                                    CalRound(
                                      new Decimal(quantityEnd)
                                        .minus(original_price)
                                        .times(unitPrice)
                                    ).toNumber()
                                  );
                                  setQuantityStart(original_price);
                                } else {
                                  setOriginalPrice(
                                    CalRound(
                                      new Decimal(original_price).times(
                                        unitPrice
                                      )
                                    ).toNumber()
                                  );
                                  setQuantityStart(defaultQuantity ?? 0);
                                }
                              }}
                            />
                            <div className="input-group-prepend">
                              <span className="input-group-text">{unit}</span>
                            </div>
                          </div>
                        </FormGroup>
                      </Col>
                    )}
                    {chargeMode !== ChargeMode.ACCUMLATIVE && (
                      <Col xs="12" sm="6">
                        <FormGroup>
                          <Label>{i18n.t("Qty.")}</Label>
                          <div className="input-group">
                            <Input
                              type="number"
                              maxLength={8}
                              name={"paidItemQtyStart-"}
                              value={quantityStart ?? defaultQuantity}
                              onChange={(e) => {
                                const qty =
                                  Number(e.target.value) ?? defaultQuantity;
                                setOriginalPrice(
                                  CalRound(
                                    new Decimal(qty ?? 1).times(unitPrice ?? 0)
                                  ).toNumber()
                                );
                                setQuantityStart(qty);
                              }}
                            />
                            <div className="input-group-prepend">
                              <span className="input-group-text">{unit}</span>
                            </div>
                          </div>
                        </FormGroup>
                      </Col>
                    )}
                    {chargeMode === ChargeMode.ACCUMLATIVE && (
                      <Col xs="12" sm="6">
                        <FormGroup>
                          <Label>{`${i18n.t("End")}`}</Label>
                          <div className="input-group">
                            <Input
                              type="number"
                              maxLength={8}
                              name={"paidItemQtyEnd-"}
                              onChange={(e) => {
                                const quantity_end = Number(e.target.value);
                                const quantity_start =
                                  quantityStart ?? defaultQuantity ?? 0;

                                setQuantityEnd(quantity_end);
                                setOriginalPrice(
                                  CalRound(
                                    new Decimal(quantity_end)
                                      .minus(quantity_start)
                                      .times(unitPrice)
                                  ).toNumber()
                                );
                              }}
                              value={quantityEnd?.toString().padStart(6, "0")}
                            />
                            <div className="input-group-prepend">
                              <span className="input-group-text">{unit}</span>
                            </div>
                          </div>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                </Col>
              )}

            {props.orderType === "Rent" && props.invoiceType === "Payment" && (
              <Col xs="12" className="mt-2">
                <PaidItemPeriod
                  name={"paidItemPeriod-"}
                  startDate={startDate ? Number(startDate) : undefined}
                  endDate={endDate}
                  dateRequired={false}
                  onChange={(e) => {
                    setStartDate(e.start_date);
                    setEndDate(e.end_date);
                  }}
                />
              </Col>
            )}
          </Row>
        </CardBody>
      </Card>
      {errorMessage && (
        <Row>
          <Col>
            <Alert
              color="danger"
              className="alert-dismissible fade show  mb-4 pt-2 font-size-13"
              role="alert"
            >
              {errorMessage?.msg}
            </Alert>
          </Col>
        </Row>
      )}
    </SubmissionModal>
  );
};

export default ChargeTypePaidItem;
