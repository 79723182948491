import React, { useEffect, useState, useMemo } from "react";
import { FormGroup, Label, InputGroup, Col, Row, Button } from "reactstrap";

import { AvField } from "availity-reactstrap-validation";
import moment, { unitOfTime } from "moment-timezone";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
// Redux
export interface RentPeriod {
  start_date: number;
  end_date?: number;
  rent_type: "YEAR" | "MONTH" | "WEEK" | "DAY";
  rent_period: number;
}
export interface RentalPeriodProps {
  name?: string;
  defaultRentType: "YEAR" | "MONTH" | "WEEK" | "DAY";
  rentPeriod?: number;
  startDate: number;
  endDate?: number;
  dateRequired?: boolean;
  disabled?: boolean;
  allow_yearly?: boolean;
  allow_monthly?: boolean;
  allow_weekly?: boolean;
  allow_daily?: boolean;
  periodTitle?: string;
  onChange?: (data: RentPeriod) => void;
}

const RentalPeriod = (props: RentalPeriodProps) => {
  const i18n = useTranslation();
  const [rentType, setRentType] = useState<"YEAR" | "MONTH" | "WEEK" | "DAY">(
    props.defaultRentType
  );
  const [rentPeriod, setRentPeriod] = useState(props.rentPeriod ?? 0);
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date | null>();

  useEffect(() => {
    if (props?.startDate) {
      setStartDate(moment.unix(props.startDate).toDate());
    }
    if (props.endDate) {
      setEndDate(moment.unix(props.endDate).toDate());
    }
  }, [props.startDate, props.endDate]);

  useEffect(() => {
    if (props.onChange && rentType && rentPeriod && startDate) {
      props.onChange({
        start_date: moment(startDate).startOf("day").unix(),
        end_date: endDate ? moment(endDate).startOf("day").unix() : undefined,
        rent_type: rentType,
        rent_period: Number(rentPeriod),
      });
    }
  }, [rentType, rentPeriod, startDate, endDate]);

  const handleContractAddPeriod = (period: number) => {
    if (!rentType) {
      return 0;
    }
    if (!startDate) {
      return 0;
    }
    if (Number(rentPeriod) + period > 0) {
      setRentPeriod((rentPeriod) => Number(rentPeriod) + period);
      setEndDate(
        moment(startDate)
          .add(
            Number(rentPeriod) + period,
            rentType as unitOfTime.DurationConstructor
          )
          .add(-1, "days")
          .toDate()
      );
    }
  };

  const handleSelectPeriod = (r: "YEAR" | "MONTH" | "WEEK" | "DAY") => {
    setRentPeriod(0);
    setRentType(r);
    setEndDate(startDate);
  };

  const errorMsg = useMemo(() => {
    if (!rentType) {
      return i18n.t("no rent type is selected");
    }
    if (props.dateRequired) {
      if (!endDate) {
        return i18n.t("end date must be entered");
      }
      if (startDate && endDate < startDate)
        return i18n.t("end date must later than start date");
    }
  }, [endDate, rentType]);

  return props.disabled ? null : (
    <Row className="mb-1">
      <Col xs="12" sm="6">
        <FormGroup>
          <div className="btn-group btn-group-toggle" data-toggle="buttons">
            {props.allow_yearly && (
              <label
                className={
                  rentType === "YEAR"
                    ? "btn btn-success active"
                    : "btn btn-success"
                }
              >
                <input
                  type="radio"
                  name="Year"
                  id="option1"
                  onClick={() => handleSelectPeriod("YEAR")}
                />
                {i18n.t("Year")}
              </label>
            )}

            {props.allow_monthly && (
              <label
                className={
                  rentType === "MONTH"
                    ? "btn btn-success active"
                    : "btn btn-success"
                }
              >
                <input
                  type="radio"
                  name="Month"
                  id="option2"
                  onClick={() => handleSelectPeriod("MONTH")}
                />
                {i18n.t("Month")}
              </label>
            )}
            {props.allow_weekly && (
              <label
                className={
                  rentType === "WEEK"
                    ? "btn btn-success active"
                    : "btn btn-success"
                }
              >
                <input
                  type="radio"
                  name="Week"
                  id="option3"
                  onClick={() => handleSelectPeriod("WEEK")}
                />
                {i18n.t("Week")}
              </label>
            )}
            {props.allow_daily && (
              <label
                className={
                  rentType === "DAY"
                    ? "btn btn-success active"
                    : "btn btn-success"
                }
              >
                <input
                  type="radio"
                  name="Day"
                  id="option4"
                  onClick={() => handleSelectPeriod("DAY")}
                />
                {i18n.t("Day")}
              </label>
            )}
          </div>
        </FormGroup>
      </Col>
      <Col xs="12" sm="6">
        <FormGroup className="rentPeriod">
          <InputGroup>
            <div onClick={() => handleContractAddPeriod(-1)}>
              <Button type="button" color="primary">
                <i className="mdi mdi-minus"></i>
              </Button>
            </div>
            <AvField
              name="rentPeriod"
              type="number"
              value={rentPeriod}
              onChange={(e: any) => {
                setRentPeriod(e.target.value);
              }}
              errorMessage={i18n.t("Min Value 1")}
              id="validationRentPeriod"
              validate={{
                required: { value: true },
                min: { value: 1 },
              }}
            />
            <div onClick={() => handleContractAddPeriod(1)}>
              <Button type="button" color="primary">
                <i className="mdi mdi-plus"></i>
              </Button>
            </div>
          </InputGroup>
        </FormGroup>
      </Col>
      <Col xs="12" sm="6">
        <FormGroup>
          <Label>{`${props.periodTitle} ${i18n.t("Start Date")}`}</Label>
          <InputGroup>
            <DatePicker
              name={`orderStartDate-${props.name}`}
              dateFormat="yyyy-MM-dd"
              selected={startDate}
              className="form-control"
              onChange={(date: Date) => setStartDate(date)}
              showYearDropdown
            />
          </InputGroup>
        </FormGroup>
      </Col>
      <Col xs="12" sm="6">
        <FormGroup>
          <Label>{`${props.periodTitle} ${i18n.t("End Date")}`}</Label>
          <InputGroup>
            <DatePicker
              dateFormat="yyyy-MM-dd"
              name={`orderEndDate-${props.name}`}
              selected={endDate}
              className="form-control"
              onChange={(date: Date) => setEndDate(date)}
              showYearDropdown
            />
            <span className="failed">{errorMsg}</span>
          </InputGroup>
        </FormGroup>
      </Col>
    </Row>
  );
};

export default RentalPeriod;
