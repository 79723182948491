import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import {
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  InputGroup,
  Col,
  Row,
  Button,
  Card,
  CardBody,
  CardSubtitle,
} from "reactstrap";
import { BxButton } from "../../Button";

import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { AvForm } from "availity-reactstrap-validation";
import moment from "moment-timezone";
import ReactTooltip from "react-tooltip";
import DatePicker from "react-datepicker";
import "./storage.scss";
// Redux
import { MODAL_TYPES } from "../ModalProps";

import { useGlobalModalContext } from "../../../context/GlobalModalContext";

import { useBranchContext } from "../../../context/BranchProviderContext";
import { useStorageContext } from "../../../context/StorageProviderContext";
import { useTranslation } from "react-i18next";
import { getPaymentItems } from "../../../resolver/index";
import { getBoxappStorageBackend } from "src/helpers/boxapp_storage_helper";
import { DepositOrder } from "src/models/depositOrder";
import { Customer } from "src/models/customer";
import { Order } from "src/models/order";
import { Contract } from "src/models/contract";
import { getBoxappContractBackend } from "src/helpers/boxapp_contract_helper";
import Decimal from "decimal.js";

interface StorageTerminateContractPanelProps extends RouteComponentProps {
  totalSteps: number;
  isFinished: boolean;
  step: number;
  open: boolean;
  mode: string;
  order: Order;
  customer: Customer;
  depositOrder: DepositOrder;
  onCancel: () => void;
  onSubmit: (data: any) => void;
  // onSuccess: (mode: string, storage: any) => void;
}

const StorageTerminateContractPanel = (
  props: StorageTerminateContractPanelProps
) => {
  const { contextStorage } = useStorageContext();
  const { i18n } = useTranslation();
  const { showModal } = useGlobalModalContext();
  const [terminateDate, setTerminateDate] = useState(new Date());
  const [remarks, setRemarks] = useState("");
  const [contract, setContract] = useState<Contract>(); // [TODO
  const [moveOutDate, setMoveOutDate] = useState(new Date());
  // const { contextBranch } = useBranchContext();

  const togglemodal = () => {
    setTerminateDate(new Date());
    setRemarks("");
    setMoveOutDate(new Date());
    props.onCancel();
  };

  useEffect(() => {
    if (props.order) {
      setRemarks(props.order ? props.order.remarks : "");
      getBoxappContractBackend()
        .getContract(props.order?.contract_id, {})
        .then((response) => {
          setContract(response.contract);
        });
    }
  }, [props.order]);

  return !props.order ? null : (
    <AvForm
      hidden={!props.open}
      action="#"
      id={"CollapseForm" + props.step}
      onSubmit={async () => {
        if (!terminateDate) {
          return;
        }
        // if (!contextBranch?.id) {
        //   return;
        // }

        // if (!contextStorage?.id) {
        //   return;
        // }
        if (props.onSubmit) {
          props.onSubmit({
            order_id: props.order.id,
            remarks: remarks,
            customer_id: props.customer.id,
            move_out_date: moveOutDate
              ? moment(moveOutDate).startOf("day").unix()
              : undefined,
            termination_date: moment(terminateDate).startOf("day").unix(),
          });
        }
        //  const response =
        //    await getBoxappStorageBackend().terminateRentStorage(
        //      contextBranch?.id,
        //      contextStorage?.id,
        //      {
        //        order_id: props.order.id,
        //        remarks: remarks,
        //        customer_id: props.customer.id,
        //        move_out_date: moment(moveOutDate).startOf("day").unix(),
        //        termination_date: moment(terminateDate)
        //          .startOf("day")
        //          .unix(),
        //      }
        //    );
        //  if (response?.storage)
        //    props.onSubmit(props.mode, response.storage);
      }}
    >
      <Card outline color="dark" className="border">
        <CardBody>
          <span className="mt-4 font-size-15 font-weight-bold">
            {`${i18n.t("Contract Details")}${
              i18n.language === "en" ? " " : ""
            }`}
          </span>

          <Row className="mt-2">
            <Col xs="6">
              <Row>
                <Col>
                  <Label>{i18n.t("Storage")} </Label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p>{contextStorage?.code}</p>
                </Col>
              </Row>
            </Col>
            <Col xs="6">
              <Row>
                <Col>
                  <Label>{i18n.t("Order No")} </Label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p>{props.order.order_no}</p>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs="6">
              <Row>
                <Col>
                  <Label>{i18n.t("Customer")} </Label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p>
                    {props.customer?.first_name + props.customer?.last_name}
                  </p>
                </Col>
              </Row>
            </Col>
            <Col xs="6">
              <Row>
                <Col>
                  <Label>{i18n.t("Contract No")} </Label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p>{contract?.id}</p>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col xs="12">
              <Row>
                <Col>
                  <Label>{i18n.t("Deposit")} </Label>
                </Col>
              </Row>
              <Row>
                <Col>{props.depositOrder?.order_no ?? "-"}</Col>
              </Row>
            </Col>
          </Row>
          <span className="mt-4 font-size-15 font-weight-bold">
            {`${i18n.t("Lastest Order Rental Period")}${
              i18n.language === "en" ? " " : ""
            }`}
          </span>

          <Row>
            <Col xs="6">
              <Row>
                <Col xs="12" className="mt-3">
                  <Label>{i18n.t("Order Start Date")} </Label>
                  <p>
                    {moment
                      .unix(props.order.actual_start_date)
                      .format("YYYY/MM/DD")}
                  </p>
                </Col>
              </Row>
            </Col>
            <Col xs="6">
              <Row>
                <Col xs="12" className="mt-3">
                  <Label>{i18n.t("Order End Date")} </Label>
                  <p>
                    {props.order.actual_end_date
                      ? moment
                          .unix(props.order.actual_end_date)
                          .format("YYYY/MM/DD")
                      : ""}
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card outline color="dark" className="border">
        <CardBody>
          <Row>
            <Col xs="12" className="mt-3">
              <Col xs="12">
                <Alert
                  color="success"
                  className="alert-dismissible fade show  mb-4 pt-2 font-size-13"
                  role="alert"
                >
                  <i className="far fa-smile mr-2"></i>
                  <p>{`${i18n.t(
                    "If the tenant's moving-out date is uncertain, it can be deleted and filled in later."
                  )}`}</p>
                  <p>{`${i18n.t(
                    "The end date of the contract must be filled in."
                  )}`}</p>
                </Alert>
              </Col>
              <Label>{i18n.t("Move Out At")}</Label>
              <InputGroup>
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  name="moveOutDate"
                  selected={moveOutDate}
                  className="form-control"
                  onChange={(date: Date) => setMoveOutDate(date)}
                  showYearDropdown
                  minDate={
                    moment.unix(props.order.actual_start_date).diff(moment()) <
                    0
                      ? new Date()
                      : moment.unix(props.order.actual_start_date).toDate()
                  }
                  maxDate={moment.unix(props.order.actual_end_date).toDate()}
                />
              </InputGroup>
            </Col>
          </Row>

          <Row>
            <Col xs="12" className="mt-3">
              <Label>{i18n.t("Terminate At")}</Label>
              {contract?.end_date && (
                <InputGroup>
                  <DatePicker
                    dateFormat="yyyy-MM-dd"
                    name="terminateDate"
                    selected={terminateDate}
                    className="form-control"
                    onChange={(date: Date) => setTerminateDate(date)}
                    showYearDropdown
                    minDate={
                      moment
                        .unix(props.order.actual_start_date)
                        .diff(moment()) < 0
                        ? new Date()
                        : moment.unix(props.order.actual_start_date).toDate()
                    }
                    maxDate={moment.unix(contract?.end_date).toDate()}
                  />
                </InputGroup>
              )}
            </Col>
          </Row>

          <Row>
            <Col xs="12" className="mt-3">
              <FormGroup>
                <Label>{i18n.t("Remarks")}</Label>
                <Input
                  type="textarea"
                  maxLength={500}
                  name="remarks"
                  onChange={(e) => setRemarks(e.target.value)}
                  value={remarks}
                />
              </FormGroup>
            </Col>
          </Row>
          {
            <BxButton
              type="submit"
              color="success"
              form={"CollapseForm" + props.step}
            >
              {props.totalSteps === props.step + 1
                ? i18n.t("Submit")
                : i18n.t("Next Step")}
            </BxButton>
          }
        </CardBody>
      </Card>
    </AvForm>
  );
};

export default withRouter(withTranslation()(StorageTerminateContractPanel));
