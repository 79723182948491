import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import propertyTranslationENG from "./locales/property/en/translation.json";
import propertyTranslationZH from "./locales/property/zh/translation.json";
import shopTranslationENG from "./locales/shop/en/translation.json";
import shopTranslationZH from "./locales/shop/zh/translation.json";
import roomTranslationENG from "./locales/room/en/translation.json";
import roomTranslationZH from "./locales/room/zh/translation.json";
import miniStoreTranslationENG from "./locales/mini-store/en/translation.json";
import miniStoreTranslationZH from "./locales/mini-store/zh/translation.json";
// the translations
i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        property: propertyTranslationENG,
        shop: shopTranslationENG,
        room: roomTranslationENG,
        "mini-store": miniStoreTranslationENG,
      },
      zh: {
        property: propertyTranslationZH,
        shop: shopTranslationZH,
        room: roomTranslationZH,
        "mini-store": miniStoreTranslationZH,
      },
    },
    // lng: localStorage.getItem("app-language") ?? "zh", // if you're using a language detector, do not define the lng option
    defaultNS: localStorage.getItem("app-namespace") ?? "property",
    fallbackLng: "zh", // use en if detected lng is not available
    keySeparator: false, // we do not use keys in form messages.welcome
    supportedLngs: ["zh", "en"],
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
