import React, { lazy, useEffect, useState, useCallback, useMemo } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Col,
  FormGroup,
  Row,
} from "reactstrap";
import { withTranslation, useTranslation } from "react-i18next";

// Redux

import "./storageRent.scss";

import { useStorageContext } from "../../../context/StorageProviderContext";

import { useBranchContext } from "../../../context/BranchProviderContext";

import { MODAL_TYPES } from "../ModalProps";
import { useGlobalModalContext } from "../../../context/GlobalModalContext";
import { getBoxappOrderBackend } from "../../../helpers/boxapp_order_helper";

import { Contract } from "src/models/contract";
import { Customer } from "src/models/customer";
import { RentMode, RentPanelSteps } from "./StorageRent";
import StorageRentDepositPanel from "./StorageRentDepositPanel";
import { MasterContract } from "src/models/masterContract";

export interface StorageRentNewDepositProps {
  mode: string;
  onSuccess: () => void;
  onClose: () => void;
  modal: boolean;
  customer: Customer;
  masterContract: MasterContract;
}

const StorageRentNewDeposit = (props: StorageRentNewDepositProps) => {
  const { i18n } = useTranslation();
  const [process, setProcess] = useState<number[]>([]);
  const { contextStorage } = useStorageContext();
  const [customer, setCustomer] = useState<Customer>(props.customer);
  const [contract, setContract] = useState<Contract>();
  const { contextBranch } = useBranchContext();
  const [submitButtonBlock, setSubmitButtonBlock] = useState(false);
  const [requestBody, setRequestBody] = useState<Map<string, any>>(new Map());
  const steps = [RentPanelSteps.Deposit];
  const { showModal } = useGlobalModalContext();

  const isFinished = useCallback(
    (i: number) => process?.indexOf(i) > -1,
    [process]
  );
  const formValid = useMemo(() => {
    if (steps.length === 1) {
      return true;
    }
    return process.length >= steps.length;
  }, [process]);

  const togglemodal = () => {
    props.onClose();
  };

  const submit = async () => {
    setSubmitButtonBlock(true);
    const interval = 2000;
    const timeoutId = setTimeout(() => {
      setSubmitButtonBlock(false);
      clearTimeout(timeoutId);
    }, interval);
    try {
      const depositReq = requestBody.get(RentPanelSteps.Deposit);
      if (depositReq && props.onSuccess) {
        await getBoxappOrderBackend().createDepositOrder({
          ...depositReq,
          master_contract_id: props?.masterContract?.id,
        });
        props.onSuccess();
      } else {
        throw new Error("Deposit request is empty");
      }
    } catch (e) {
      const errorMsg =
        (e as any)?.data?.detail ??
        "" + (e as any)?.data?.message ??
        "" + e?.toString();
      showModal(MODAL_TYPES.ALERT_MODAL, {
        title: i18n.t("rent rental location failed"),
        messageType: "error",
        message: errorMsg,
      });
    }
  };

  return !contextStorage ? null : (
    <React.Fragment>
      <Modal
        size="lg"
        isOpen={props.modal}
        role="dialog"
        backdrop="static"
        autoFocus={true}
        centered={true}
        className="rent"
        tabIndex={-1}
        toggle={togglemodal}
      >
        <div className="modal-content">
          <ModalHeader toggle={togglemodal}>{i18n.t("Despoit")}</ModalHeader>

          <ModalBody>
            <StorageRentDepositPanel
              step={0}
              depositRequire={true}
              onSubmit={async (e: any) => {
                if (e?.deposit_require) {
                  setRequestBody(
                    requestBody.set(steps[0], {
                      ...e,
                      customer_id: customer?.id,
                      // start_date: contract?.start_date,
                      // end_date: contract?.end_date,
                      // rent_type: contract?.rent_type,
                      // rent_period: contract?.rent_period,
                      storage_id: contextStorage?.id,
                      branch_id: contextBranch?.id,
                      master_contract_id: props?.masterContract?.id,
                    })
                  );
                  submit();
                }
              }}
              branch={contextBranch}
              storage={contextStorage}
              // mode={props.mode}
              isFinished={true}
              onCancel={() => {
                setProcess(process.filter((p) => p !== 2));
              }}
              open={true}
            ></StorageRentDepositPanel>

            {/* <Row>
              <Col xs={12}>
                {currentStep === steps.length - 1 && (
                  <BxButton
                    loading={submitButtonBlock}
                    disabled={!formValid || submitButtonBlock}
                    className="btn"
                    type="button"
                    color="success"
                    onClick={submit}
                  >
                    {i18n.t("Submit")}
                  </BxButton>
                )}
              </Col>
            </Row> */}
          </ModalBody>
          <ModalFooter></ModalFooter>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default withTranslation()(StorageRentNewDeposit);
