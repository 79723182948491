import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, Alert } from "reactstrap";
import DataTable from "../../DataTable";
import { truncatedString } from "../../../util/string";

import { useTranslation } from "react-i18next";
import { getBoxappCustomerBackend } from "../../../helpers/boxapp_customer_helper";
import { useGlobalModalContext } from "../../../context/GlobalModalContext";
import { Branch } from "../../../models/branch";
import { Customer } from "../../../models/customer";
import { MODAL_TYPES } from "../ModalProps";
import { Column } from "../../DataTable/ColumnFilter";
import { set } from "immutable";

export interface CustomerSearchProps {
  modal?: boolean;
  id: string;
  onClose: () => void;
  branch: Branch;
  onSearched: (customer: Customer) => void;
}

const CustomerSearch = (props: CustomerSearchProps) => {
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState<any>({});
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [totalData, setTotalDate] = useState<number>(0);
  const [totalPage, setTotalPage] = useState<number>(0);
  const { i18n } = useTranslation();
  const { showModal } = useGlobalModalContext();
  const togglemodal = () => {
    props.onClose();
  };
  let filterTimeout: NodeJS.Timeout;
  const setSearchText = (filter: any) => {
    clearTimeout(filterTimeout);
    if (!filter) return;

    filterTimeout = setTimeout(() => {
      setSearch(filter);
      fetchCustomers(filter, page, limit);
    }, 800);
  };

  const fetchCustomers = async (filter: any, page: number, limit: number) => {
    setLoading(true);
    try {
      setCustomers([]);
      const response = await getBoxappCustomerBackend().getCustomers({
        ...filter,
        branch_ids: props.branch?.id ?? undefined,
        page: page,
        limit: limit,
      });
      setCustomers(response.customers?.data ?? []);
      setTotalPage(response?.customers?.pageCount ?? 0);
      setPage(response.customers?.page ?? 0);
      setTotalDate(response.customers?.total ?? 0);
    } catch (e) {
      const errorMsg =
        (e as any)?.data?.detail ??
        "" + (e as any)?.data?.message ??
        "" + e?.toString();
      showModal(MODAL_TYPES.ALERT_MODAL, {
        title: i18n.t("fetch customers failed"),
        messageType: "error",
        message: errorMsg,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCustomers(search, page, limit);
  }, []);

  const getRows = () => {
    return customers.map((customer, i) => {
      const row: any = Object.assign({}, customer);
      row["name"] = customer.other_name;
      row["customerTypeGroup"] = i18n.t(customer.customer_type);
      row["idNo"] = truncatedString(customer.id_no, 19);
      row["phone"] = customer.phone;
      row.data = customer;
      return row;
    });
  };
  const data = {
    columns: [
      {
        label: i18n.t("Customer Name"),
        field: "name",
        sort: "asc",
        searchable: true,
      },
      {
        label: i18n.t("Customer Type"),
        field: "customerTypeGroup",
        sort: "asc",
        searchable: true,
      },
      {
        label: i18n.t("idNo"),
        field: "idNo",
        sort: "asc",
        searchable: true,
      },
      {
        label: i18n.t("Phone"),
        field: "phone",
        sort: "asc",
        searchable: true,
      },
    ],
    rows: getRows() as any,
  } as {
    columns: Column[];
    rows: any[];
  };
  return (
    <React.Fragment>
      <Modal
        isOpen={props.modal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="searchModal"
        toggle={togglemodal}
      >
        <div className="modal-content">
          <ModalHeader toggle={togglemodal}>
            {i18n.t("Search Customer")}
          </ModalHeader>
          <ModalBody>
            <Alert
              color="success"
              className="alert-success fade show  mb-4 pt-2"
              role="alert"
            >
              <i className="far fa-smile mr-2"></i>
              {i18n.t("Can show the assigned branch customer only.")}
              <a
                href={`/customers`}
                className="ml-4 font-size-12 font-italic font-weight-light secondary text-decoration-underline"
              >
                <i className="mdi mdi-alert"></i>
                {i18n.t("Direct to Customer Page")}
              </a>
            </Alert>
            <DataTable
              noRecordsFoundLabel={i18n.t("No Records")}
              responsive
              striped
              displayEntries={false}
              data={data}
              loading={loading}
              activePage={Number(page)}
              totalPage={Number(totalPage)}
              totalData={Number(totalData)}
              entitiesCount={Number(limit)}
              onClear={() => {
                setSearchText({});
              }}
              placeholderText={`${i18n.t("Please search by")}: ${i18n.t(
                "id_no"
              )}, ${i18n.t("Phone")}`}
              onSearch={(search: string) => {
                setSearchText({
                  phone: search,
                  id_no: search,
                });
              }}
              onEntitiesCountChange={(limit) => {
                setLimit(limit);
                fetchCustomers(search, 0, limit);
              }}
              onRowClick={(row) => {
                props.onSearched(row.data as Customer);
              }}
              onPage={(page) => {
                fetchCustomers(search, page, limit);
              }}
            />
          </ModalBody>
        </div>
      </Modal>
    </React.Fragment>
  );
};
export default CustomerSearch;
