import React, { useEffect, useState, useMemo, useCallback } from "react";

import "./invoiceInfo.scss";
import moment from "moment-timezone";

import {
  Alert,
  FormGroup,
  Row,
  Col,
  Input,
  Button,
  Label,
  Card,
  CardBody,
} from "reactstrap";

// Redux
import SubmissionModal from "../../../components/Modal/SubmissionModal";

import { useTranslation } from "react-i18next";
import { Order } from "src/models/order";
import { Branch } from "src/models/branch";
import AsyncSelect from "react-select/async";
import {
  PaidItemChargeTypeOption,
  optionPaidItemchargeType,
} from "src/util/options";
import PaidItemPeriod from "src/components/Modal/Storages/PaidItemPeriod";
import { ChargeType } from "src/models/chargeType";
import { PaidItem } from "src/models/paidItem";
import { InvoiceType } from "src/models/invoice";
import Decimal from "decimal.js";
import { OrderType } from "src/util/orderType";
import { MultiLangText } from "src/models/multiLangText";
import { set } from "immutable";
import { ChargeMode } from "src/util/chargeMode";

export interface MiscellaneousPaidItemProps {
  id?: string;
  order?: Order;
  branch: Branch;
  // storage: Storage;
  // mode: string;
  modal: boolean;
  item?: PaidItem;

  invoiceType: InvoiceType;
  orderType: OrderType;
  onSuccess: (paidItem: PaidItem) => void;
  onFailed: () => void;
  onClose: () => void;
}

export const MiscellaneousPaidItem = (props: MiscellaneousPaidItemProps) => {
  const { i18n } = useTranslation();
  const [lastOrderPaidItem, setLastOrderPaidItem] = useState<PaidItem>();

  const [errorMessage, setErrorMessage] = useState<any>();
  // const [paidType, setPaidType] = useState<PaidItemChargeTypeOption>();
  const [originalPrice, setOriginalPrice] = useState<number>(0);
  const [quantize, setQuantize] = useState<boolean>(false);
  const [unitPrice, setUnitPrice] = useState<number>(0);
  const [unit, setUnit] = useState<string>();
  const [quantityStart, setQuantityStart] = useState<number>(1);
  const [chargeName, setChargeName] = useState<string>();
  const [startDate, setStartDate] = useState<number | undefined>(
    moment().unix()
  );
  const [endDate, setEndDate] = useState<number>();
  useEffect(() => {
    if (props.item) {
      setOriginalPrice(Number(props.item?.original_price) ?? 0);
      setQuantize(props.item?.quantize ?? false);
      setUnitPrice(props.item?.unit_price ?? 0);
      setUnit(props.item?.unit);
      setQuantityStart(props.item?.quantity_start ?? 1);
      setChargeName(props.item?.item_name?.en ?? "");
      setStartDate((props.item?.start_date as number) ?? 0);
      setEndDate((props.item?.end_date as number) ?? 0);
    }
  }, [props.item]);

  const onSubmit = async () => {
    const discount_amount = 0;

    if ((originalPrice ?? 0) < 0) {
      setErrorMessage(i18n.t("paid item price is invalid"));
      return;
    }

    setErrorMessage(undefined);

    props.onSuccess({
      id: props.item?.id,
      order_id: props.order?.id,
      order_type: props.order?.order_type,
      item_id: undefined,
      item_type: "MISCELLANEOUS",
      item_name: {
        en: chargeName,
        zh: chargeName,
        cn: chargeName,
      } as MultiLangText,

      item: {
        id: undefined,
        quantize: true,
        // charge_type_category_id: paidType?.charge_type_category_id,
        // charge_type_category: paidType?.charge_type_category,
        charge_name: {
          en: chargeName,
          zh: chargeName,
          cn: chargeName,
        } as MultiLangText,
        charge_mode: ChargeMode.STAND_ALONE,
        // unit_price: paidType?.unit_price,
        // unit: paidType?.unit,
        // default_quantity: paidType?.default_quantity,
        // branches: paidType?.branches,
        // charge_type_id: paidType?.charge_type_id,
        // sort: paidType?.sort,
        // branch_id: paidType?.branch_id,
      } as ChargeType,
      start_date: startDate,
      end_date: endDate,
      final_price: originalPrice - discount_amount,
      quantize: quantize,
      // unit_price: unitPrice,
      quantity_start: quantityStart ?? 1,
      // quantity_end: undefined,
      // default_quantity: undefined,
      original_price: originalPrice,
      charge_mode: ChargeMode.STAND_ALONE,
      unit: unit,
      discount_amount: discount_amount,
      status: "DRAFT",
      
                    sort: props.item?.sort ?? 0,
    });
  };

  const onClose = () => {
    props.onClose();
  };

  return (
    <SubmissionModal
      id={props.id}
      size={"xl"}
      onSubmit={onSubmit}
      onClose={onClose}
      title={`${i18n.t("Other Charge Items")}`}
      show={props.modal ?? false}
    >
      <Card className="border">
        <CardBody>
          {lastOrderPaidItem && (
            <Alert
              color="warning"
              className="alert-dismissible fade show  mb-4 pt-2 font-size-13"
              role="alert"
            >
              {lastOrderPaidItem?.quantize &&
                lastOrderPaidItem?.charge_mode === ChargeMode.ACCUMLATIVE && (
                  <i className="mdi mdi-calendar-arrow-left">
                    {i18n.t("Last Order Price")}:{" "}
                    {lastOrderPaidItem?.original_price}
                    <br />
                    {i18n.t("Last Quantity End")}:{" "}
                    {lastOrderPaidItem?.quantity_end}
                    {lastOrderPaidItem?.unit}
                    <br />
                    {i18n.t("Last Start Date")}:{" "}
                    {lastOrderPaidItem?.start_date
                      ? moment(
                          (lastOrderPaidItem?.start_date as number) * 1000
                        ).format("YYYY-MM-DD")
                      : "N/A"}
                    <br />
                    {i18n.t("Last End Date")}:{" "}
                    {lastOrderPaidItem?.end_date
                      ? moment(
                          (lastOrderPaidItem?.end_date as number) * 1000
                        ).format("YYYY-MM-DD")
                      : "N/A"}
                  </i>
                )}
              {lastOrderPaidItem?.quantize &&
                lastOrderPaidItem?.charge_mode === ChargeMode.STAND_ALONE && (
                  <i className="mdi mdi-calendar-arrow-left">
                    {i18n.t("Last Order Price")}:{" "}
                    {lastOrderPaidItem?.original_price}
                    <br />
                    {i18n.t("Last Quantity")}: {lastOrderPaidItem?.quantity_end}
                    {lastOrderPaidItem?.unit}
                    <br />
                    {i18n.t("Last Start Date")}:{" "}
                    {lastOrderPaidItem?.start_date
                      ? moment(
                          (lastOrderPaidItem?.start_date as number) * 1000
                        ).format("YYYY-MM-DD")
                      : "N/A"}
                    <br />
                    {i18n.t("Last End Date")}:{" "}
                    {lastOrderPaidItem?.end_date
                      ? moment(
                          (lastOrderPaidItem?.end_date as number) * 1000
                        ).format("YYYY-MM-DD")
                      : "N/A"}
                  </i>
                )}
              {!lastOrderPaidItem?.quantize && (
                <i className="mdi mdi-calendar-arrow-left">
                  {i18n.t("Last Order Price")}:{" "}
                  {lastOrderPaidItem?.original_price}
                  <br />
                  {i18n.t("Last Start Date")}:{" "}
                  {lastOrderPaidItem?.start_date
                    ? moment(
                        (lastOrderPaidItem?.start_date as number) * 1000
                      ).format("YYYY-MM-DD")
                    : "N/A"}
                  <br />
                  {i18n.t("Last End Date")}:{" "}
                  {lastOrderPaidItem?.end_date
                    ? moment(
                        (lastOrderPaidItem?.end_date as number) * 1000
                      ).format("YYYY-MM-DD")
                    : "N/A"}
                </i>
              )}
            </Alert>
          )}
          <Row className="ml-2 mb-1">
            <Col
              xs={
                props.orderType === "Rent" && props.invoiceType === "Payment"
                  ? 10
                  : 4
              }
            >
              <Label>{i18n.t("Paid Type")}</Label>
              <Input
                type="text"
                name={"chargeType-"}
                disabled={true}
                value={"Miscellaneous"}
              />
            </Col>
            <Col xs="1"></Col>
            <Col xs="1"></Col>
            <Col
              xs={
                props.orderType === "Rent" && props.invoiceType === "Payment"
                  ? 10
                  : 4
              }
            >
              <Label>{i18n.t("Name")}</Label>
              <Input
                type="text"
                name={"name-"}
                value={chargeName}
                onChange={(e) => {
                  setChargeName(e?.target?.value);
                }}
              />
            </Col>
            <Col xs="1"></Col>
            <Col xs="1"></Col>
            <Col
              xs={
                props.orderType === "Rent" && props.invoiceType === "Payment"
                  ? 6
                  : 4
              }
            >
              <Label>{i18n.t("Price")}</Label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">$</span>
                </div>
                <Input
                  type="number"
                  maxLength={8}
                  name={"paidItemPrice-"}
                  onChange={(e) => {
                    const price = new Decimal(e.target.value ?? 0);
                    setOriginalPrice(price?.toNumber());
                  }}
                  value={originalPrice}
                />
              </div>
            </Col>

            {props.orderType === "Rent" && props.invoiceType === "Payment" ? (
              <>
                <Col xs={2}>
                  <FormGroup>
                    <Label>{i18n.t("Qty.")}</Label>
                    <div className="input-group">
                      <Input
                        type="number"
                        maxLength={8}
                        name={"paidItemQtyStart-"}
                        value={quantityStart ?? 1}
                        onChange={(e) => {
                          const qty = Number(e.target.value) ?? 1;

                          setQuantityStart(qty);
                        }}
                      />
                    </div>
                  </FormGroup>
                </Col>

                <Col xs={2}>
                  <FormGroup>
                    <Label>{i18n.t("Unit")}</Label>

                    <div className="input-group">
                      <Input
                        type="text"
                        maxLength={8}
                        name={"paidItemUnit-"}
                        value={unit}
                        onChange={(e) => {
                          setUnit(e?.target?.value);
                        }}
                      />
                    </div>
                  </FormGroup>
                </Col>
              </>
            ) : (
              ""
            )}

            {props.orderType === "Rent" && props.invoiceType === "Payment" && (
              <Col xs="12" className="mt-2">
                <PaidItemPeriod
                  name={"paidItemPeriod-"}
                  startDate={startDate ? Number(startDate) : undefined}
                  endDate={endDate}
                  dateRequired={false}
                  onChange={(e) => {
                    setStartDate(e.start_date);
                    setEndDate(e.end_date);
                  }}
                />
              </Col>
            )}
          </Row>
        </CardBody>
      </Card>
      {errorMessage && (
        <Row>
          <Col>
            <Alert
              color="danger"
              className="alert-dismissible fade show  mb-4 pt-2 font-size-13"
              role="alert"
            >
              {errorMessage?.msg}
            </Alert>
          </Col>
        </Row>
      )}
    </SubmissionModal>
  );
};

export default MiscellaneousPaidItem;
