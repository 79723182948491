import axios from "axios";
import { BoxappBackend } from "./boxapp_helper";
import { Data, ResponseResult } from "../api/reponseResult";

class BoxappCustomFieldBackend extends BoxappBackend {
  getCustomFields = async (params: {
    custom_object: string,
    status: string,
  }): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/custom-fields`, params);
    } catch (err) {
      throw err;
    }
  };
  createCustomField = async (field: any): Promise<Data> => {
    try {
      return await this.post(`${this.baseURL}/custom-fields`, field);
    } catch (err) {
      throw err;
    }
  };

  updateCustomField = async (id: string, field: any): Promise<Data> => {
    try {
      return await this.put(`${this.baseURL}/custom-fields/${id}`, field);
    } catch (err) {
      throw err;
    }
  };
}

let _BoxappCustomFieldBackend: BoxappCustomFieldBackend | null = null;

/**
 * Returns the firebase backend
 */
export const getBoxappCustomFieldBackend = () => {
  if (!_BoxappCustomFieldBackend) {
    _BoxappCustomFieldBackend = new BoxappCustomFieldBackend();
  }
  return _BoxappCustomFieldBackend;
};
