import React, { useEffect, useState, useMemo, useCallback } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";
import { withRouter } from "react-router-dom";
import "./storage.scss";
import { withTranslation } from "react-i18next";
import StoragePaymentPanel from "./StoragePaymentPanel";

const StoragePaymentInfo = (props) => {
  const order = useMemo(
    () => (props.order?.status === "COMPLETED" ? props.newOrder : props.order),
    [props.order, props.newOrder]
  );

  const invoice = useMemo(() => {
    if (props.invoice) {
      return props.invoice
    }
    if (order?.order_type === "Deposit") {
      return order?.invoices?.find((inv) => inv.status !== "COMPLETED");
    }
    return order?.invoices?.find(
      (inv) =>
        inv.status !== "COMPLETED" &&
        inv?.paid_items.find((paid_item) => paid_item?.item_type === "STORAGE")
    );
  }, [order]);



  const togglemodal = () => {
    props.onClose();
  };

  const titles = useMemo(
    () =>
      !props.depositOrder
        ? []
        : [props.t("Deposit Order"), props.t("Rental Order")],
    [props.depositOrder]
  );

  return (
    <Modal
      isOpen={props.modal}
      role="dialog"
      backdrop="static"
      autoFocus={true}
      centered={true}
      tabIndex="-1"
      size="xl"
      fullscreen={true}
      toggle={togglemodal}
    >
      <div className="modal-content">
        <ModalHeader toggle={togglemodal} tag="h4">
          <span>{props.t("Payment Info")}</span>
        </ModalHeader>
        <ModalBody>
          <div>
            <StoragePaymentPanel
              mode={props.mode}
              titles={titles}
              order={order}
              invoice={invoice}
              onPaidSuccess={(p) => {
                if (props.onPaidSuccess)
                  props.onPaidSuccess(p);
              }}
              onSuccess={(p) => {
                if (props.onSuccess)
                  props.onSuccess(props.mode, p);
              }}
              onClose={() => togglemodal()}
            />
          </div>
        </ModalBody>
      </div>
    </Modal>
  );
};

export default withRouter(withTranslation()(StoragePaymentInfo));
