import React, { useState, useEffect, useMemo } from "react";
import { withTranslation } from "react-i18next";
import {
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  InputGroup,
  Col,
  Row,
  Button,
  Card,
  CardBody,
  CardSubtitle,
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { BxButton } from "../../Button";

import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { AvForm } from "availity-reactstrap-validation";
import moment from "moment-timezone";
import ReactTooltip from "react-tooltip";
import "./storage.scss";
// Redux
import { useTranslation } from "react-i18next";
import { Customer } from "src/models/customer";
import { Order } from "src/models/order";
import { Tr, Th, Td } from "react-super-responsive-table";
import { ChargeType } from "src/models/chargeType";
import { InvoiceType } from "src/models/invoice";
import { MultiLangText } from "src/models/multiLangText";
import { PaidItem } from "src/models/paidItem";
import { Wallet } from "src/models/wallet";
import ChargeTypePaidItem from "src/pages/Orders/components/ChargeTypePaidItem";
import StoragePaidItem from "src/pages/Orders/components/StoragePaidItem";
import { Storage } from "src/models/storage";
import { Branch } from "src/models/branch";
import Decimal from "decimal.js";
import { OrderType } from "src/util/orderType";

interface StorageTerminateRefundPanelProps extends RouteComponentProps {
  branch?: Branch;
  totalSteps: number;
  isFinished: boolean;
  paidItems: PaidItem[];
  step: number;
  open: boolean;
  mode: string;
  order: Order;
  storage?: Storage;
  // customer: Customer;
  // depositOrder: DepositOrder;
  onCancel: () => void;
  onSubmit: (data: any) => void;
}

const StorageTerminateRefundPanel = (
  props: StorageTerminateRefundPanelProps
) => {
  const { i18n } = useTranslation();
  const [showAlert, setShowAlert] = useState(false);
  const [remarks, setRemarks] = useState<string>();
  const [confirmAmountButton, setConfirmAmountButton] = useState(false);
  const [errorMessage, setErrorMessage] = useState<Map<string, string>>(
    new Map()
  );
  const depositAmount = useMemo(() => {
    return props.order?.invoices
      ?.filter((invoice) => invoice?.status === "COMPLETED")
      ?.reduce((a, invoice) => {
        const paidAmount = invoice?.paid_items?.reduce(
          (a, paid_item) => (a = a.plus(paid_item?.paid_amount ?? 0)),
          new Decimal(0)
        );

        return a.plus(paidAmount ?? 0);
      }, new Decimal(0));
  }, [props.order]);

  const getPaidItemsByType = (invoiceType: string) => {
    return (
      props?.order?.invoices
        ?.filter((invoice) => invoice.invoice_type === invoiceType)
        ?.flatMap((invoice) => invoice.paid_items) ?? []
    );
  };

  const paymentPaidItems = useMemo(
    () => getPaidItemsByType("Payment"),
    [props?.order]
  );
  const refundPaidItems = useMemo(
    () => getPaidItemsByType("Refund"),
    [props?.order]
  );
  const forfeitPaidItems = useMemo(
    () => getPaidItemsByType("Forfeit"),
    [props?.order]
  );

  const totalForfeitAmount = useMemo(() => {
    return forfeitPaidItems
      ?.filter(
        (paidItem) =>
          paidItem?.item_type !== "WALLET" && paidItem?.item_type !== "PREPAID"
      )
      ?.reduce((a, v) => (a = a.plus(v?.paid_amount ?? 0)), new Decimal(0));
  }, [forfeitPaidItems]);

  const totalRefundPrice = useMemo(() => {
    return refundPaidItems
      ?.filter(
        (paidItem) =>
          paidItem?.item_type !== "WALLET" && paidItem?.item_type !== "PREPAID"
      )
      ?.reduce((a, v) => (a = a.plus(v?.paid_amount ?? 0)), new Decimal(0));
  }, [refundPaidItems]);

  const title = () => {
    if (props.isFinished) {
      const storage = props.paidItems?.find((x) => x?.item_type === "STORAGE");
      if (storage) {
        return `(${moment
          .unix(Number(storage?.start_date))
          ?.format("YYYY-MM-DD")} - ${moment
          .unix(Number(storage?.end_date))
          ?.format("YYYY-MM-DD")})`;
      } else {
        //find some other item with start date and end date
        const item = props.paidItems?.find((x) => x?.start_date && x?.end_date);
        if (item) {
          return `(${moment
            .unix(Number(item?.start_date))
            ?.format("YYYY-MM-DD")} - ${moment
            .unix(Number(item?.end_date))
            ?.format("YYYY-MM-DD")})`;
        } else {
          const item = props.paidItems?.find((x) => x?.start_date);
          return `(${moment
            .unix(Number(item?.start_date))
            ?.format("YYYY-MM-DD")}  `;
        }
      }
    }
    return "";
  };

  interface TableItem {
    item_type?: string;
    status?: string;
    item_name?: MultiLangText;
    id?: string | number;
    final_price?: string | number;
  }

  interface RenderTableProps {
    title: string;
    items: (TableItem | undefined)[];
  }

  const RenderTable: React.FC<RenderTableProps> = ({ title, items }) => (
    <Row className="mb-4">
      <Col xs={12}>
        <Label className="font-size-13 font-weight-bold">{i18n.t(title)}</Label>
        <table className="table table-striped ">
          <thead>
            <Tr>
              <Th className="co-name text-center">{i18n.t("Paid Items")}</Th>
              <Th className="co-name">{i18n.t("Item Type")}</Th>
              <Th className="co-name">{i18n.t("Items Price")}</Th>
              <Th className="co-name">{i18n.t("Status")}</Th>
            </Tr>
          </thead>
          <tbody>
            {items.map((item, i) => (
              <Tr
                key={i}
                xl="2"
                lg="2"
                sm="4"
                xs="6"
                className={`col-sm-1 col-md-1 mt-1 mr-1`}
              >
                <Td>
                  {item?.item_type === "STORAGE"
                    ? `${i18n.t(item?.item_type)} - ${
                        item?.item_name?.[
                          i18n.language as keyof MultiLangText
                        ] ?? ""
                      }`
                    : item?.item_name?.[i18n.language as keyof MultiLangText] ??
                      ""}
                  {(
                    item?.id && errorMessage.get(String(item?.id))
                  )?.toString() ?? ""}
                </Td>
                <Td>{i18n.t(item?.item_type ?? "")}</Td>
                <Td>{item?.final_price ?? ""}</Td>
                <Td>{i18n.t(item?.status ?? "")}</Td>
              </Tr>
            ))}
          </tbody>
        </table>
      </Col>
    </Row>
  );

  return (
    <div>
      <AvForm
        hidden={!props.open}
        action="#"
        id={"CollapseForm" + props.step}
        title={`${i18n.t("Refund")} ${title()}`}
        onCancel={props.onCancel}
        open={props.open}
        onSubmit={() => {
          props.onSubmit({});
        }}
      >
        <Card outline color="dark" className="border">
          <CardBody>
            <Row className="mt-3 mb-2">
              {/* <Col xs="10">
                <Label className="font-size-13 font-weight-bold" htmlFor="">
                  {`${i18n.t("Deposit")}${
                    i18n.language === "en" ? " " : ""
                  }${i18n.t("Remarks")}`}
                </Label>
              </Col> */}
              <Col xs="12">
                <Alert
                  color="success"
                  className="alert-dismissible fade show  mb-4 pt-2 font-size-13"
                  role="alert"
                >
                  <i className="far fa-smile mr-2"></i>
                  <p>{`${i18n.t(
                    "The deposit is temporarily held by your company, so it needs to be addressed upon termination of the contract."
                  )}`}</p>
                  <p>{`${i18n.t(
                    "You can address it before or after the customer moves out."
                  )}`}</p>
                  <p>{`${i18n.t(
                    "And the deposit must be refunded to the customer, or confiscated by your company."
                  )}`}</p>
                </Alert>
              </Col>
              <Col xs={12} className="error">
                {errorMessage.get("paid_items")?.toString()}
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card outline color="dark" className="border">
          <CardBody>
            <RenderTable
              title="Deposit Remain (Waiting for handle)"
              items={paymentPaidItems.filter(Boolean)}
            />
          </CardBody>
        </Card>
        <Card outline color="dark" className="border">
          <CardBody>
            <RenderTable
              title="Refund"
              items={refundPaidItems.filter(Boolean)}
            />
          </CardBody>
        </Card>
        <Card outline color="dark" className="border">
          <CardBody>
            <RenderTable
              title="Forfeit"
              items={forfeitPaidItems.filter(Boolean)}
            />
          </CardBody>
        </Card>
        <Card outline color="dark" className="border">
          <CardBody>
            <Row>
              <Col xs="12">
                <FormGroup className="mb-4">
                  <Label className="font-size-13 font-weight-bold">{`${i18n.t(
                    "Order"
                  )} ${i18n.t("Deposit Amount")}`}</Label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text font-size-13 font-weight-bold">
                        $
                      </span>
                    </div>
                    <Input
                      disabled={true}
                      className="bg-light text-gray bg-opacity-10 font-size-15"
                      type="number"
                      maxLength={8}
                      name="depositAmount"
                      value={depositAmount?.toNumber() ?? 0}
                    />
                  </div>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col xs="12">
                <FormGroup className="mb-4">
                  <Label className="font-size-13 font-weight-bold">{`${i18n.t(
                    "Order"
                  )} ${i18n.t("Total Refund Amount")}`}</Label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text font-size-13 font-weight-bold">
                        $
                      </span>
                    </div>
                    <Input
                      disabled
                      className="bg-light text-gray bg-opacity-10 font-size-15"
                      type="number"
                      maxLength={8}
                      name="totalRefundPrice"
                      value={totalRefundPrice.toNumber() ?? 0}
                    />
                  </div>
                  <div className="error">
                    {errorMessage.get("totalRefundPrice")?.toString() ?? ""}
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <FormGroup className="mb-4">
                  <Label className="font-size-13 font-weight-bold">{`${i18n.t(
                    "Order"
                  )} ${i18n.t("Total Forfeit Amount")}`}</Label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text font-size-13 font-weight-bold">
                        $
                      </span>
                    </div>
                    <Input
                      disabled
                      className="bg-light text-gray bg-opacity-10 font-size-15"
                      type="number"
                      maxLength={8}
                      name="totalForfeitAmount"
                      value={totalForfeitAmount?.toNumber() ?? 0}
                    />
                  </div>
                </FormGroup>
              </Col>
            </Row>

            <BxButton
              className="bg-success mb-4"
              onClick={() => {
                setShowAlert(true);
                let pendingItems = props?.paidItems.filter(
                  (paidItem) => paidItem.status === "PENDING"
                );
                let isAnyItemPending = pendingItems.length > 0;
                setConfirmAmountButton(
                  depositAmount && !isAnyItemPending
                    ? depositAmount.eq(0)
                    : false
                );
              }}
            >
              {i18n.t("Check Deposit Amount & Status")}
            </BxButton>

            <Row>
              <Col>
                {showAlert && confirmAmountButton && (
                  <Alert
                    color="success"
                    className="offcanvas alert-dismissible fade show mb-4 p-3 text-center font-size-13"
                    role="alert"
                  >
                    <p>
                      <b>
                        <p>{i18n.t("You can terminate this Contract Now.")}</p>
                      </b>
                    </p>
                  </Alert>
                )}

                {showAlert && !confirmAmountButton && (
                  <Alert
                    color="warning"
                    className="offcanvas alert-dismissible fade show mb-4 p-3 text-center font-size-13"
                    role="alert"
                  >
                    <p>
                      <b>
                        <p>
                          {i18n.t(
                            "Please note that you can now proceed to click 'Next' and 'Submit' to terminate the contract."
                          )}
                        </p>
                        <p>
                          {i18n.t(
                            "Be aware that the process is only truly completed once any remaining deposit (whether to be refunded or confiscated) has been settled, bringing the deposit amount to zero."
                          )}
                        </p>
                        <p>
                          {i18n.t(
                            "You can return to the contract management page at any time to properly address any deposit issues."
                          )}
                        </p>
                      </b>
                    </p>
                  </Alert>
                )}
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <FormGroup className="border-top border-5 pt-4 md-4">
                  <Label>{`${i18n.t("Deposit")} ${i18n.t("Remarks")}`}</Label>
                  <Input
                    type="textarea"
                    // disabled
                    maxLength={500}
                    name="remarks"
                    onChange={(e) => setRemarks(e.target.value)}
                    value={remarks}
                  />
                </FormGroup>
              </Col>
            </Row>
            {!props.isFinished && (
              <BxButton
                type="submit"
                color="success"
                form={"CollapseForm" + props.step}
                // disabled={!confirmAmountButton}
              >
                {i18n.t("Next Step")}
              </BxButton>
            )}
          </CardBody>
        </Card>
      </AvForm>
    </div>
  );
};

export default withRouter(withTranslation()(StorageTerminateRefundPanel));
