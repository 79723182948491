import { OrderType } from "src/util/orderType";
import { PaidItem } from "./paidItem";
import { Payment } from "./payment";
import { Transaction } from "./transaction";
import { Order } from "./order";
import { DepositOrder } from "./depositOrder";

export interface Invoice {
  id?: string;
  invoice_no?: string;
  order_id?: string;
  invoice_type: InvoiceType;
  payments?: Payment[];
  amount: number;
  created_at?: number;
  updated_at?: number;
  paid_items?: PaidItem[];
  remarks?: string;
  transactions?: Transaction[];
  is_bad_debt?: boolean;
  status:
    | "DRAFT"
    | "PARTIAL_COMPLETED"
    | "COMPLETED"
    | "CANCELLED"
    | "PENDING"
    | "VOID"
    | "UNALLOCATED"
    | "FORFEIT";
  order_type: OrderType;
  order?: Order;
  deposit_order?: DepositOrder;
}

export enum InvoiceType {
  Payment = "Payment",
  Forfeit = "Forfeit",
  Refund = "Refund",
  Draft = "Draft",
}

export const invoiceStatusBadge = {
  DRAFT: "badge-secondary",
  COMPLETED: "badge-success",
  SETTLED: "badge-success",
  PENDING: "badge-warning",
  CANCELLED: "badge-secondary",
  VOID: "badge-secondary",
  PARTIAL_COMPLETED: "badge-danger",
  UNALLOCATED: "badge-danger",
  FORFEIT: "badge-primary",
};



export const invoiceTypeBadge = {
  Payment: "badge-success",
  Forfeit: "badge-primary",
  Refund: "badge-danger",
  Draft: "badge-secondary",

};