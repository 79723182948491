import axios from "axios";
import { BoxappBackend } from "./boxapp_helper";
import { Customer, CustomersMeta } from "../models/customer";
import { Data, ResponseResult } from "../api/reponseResult";
import { CustomerType } from "src/util/customerType";
import { store } from "src/util/storage";
import { MultiLangText } from "src/models/multiLangText";

class BoxappCustomerBackend extends BoxappBackend {
  /**
  /**
   * 
   * @param {*} error 
   * @returns 
   */

  getCustomers = async (filter: any): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/customers`, filter);
    } catch (err) {
      throw err;
    }
  };
  getCustomer = async (id: string): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/customers/${id}`);
    } catch (err) {
      throw err;
    }
  };

  getCustomerRentRecords = async (id: string, query: any): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/customers/${id}/rent-records`, query);
    } catch (err) {
      throw err;
    }
  };
  createCustomer = async (customer: {
    first_name?: string;
    last_name?: string;
    other_name?: string;
    customer_type: CustomerType;
    phone?: string;
    id_no: string;
    branches?: string[];
    status: "Active" | "Disable";
    code: string;
    address?: MultiLangText;
    title: string;
    remarks?: string;
    email?: string;
  }): Promise<Data> => {
    try {
      return await this.post(`${this.baseURL}/customers`, customer);
    } catch (err) {
      throw err;
    }
  };

  updateCustomer = async (
    id: string,
    param: {
      first_name?: string;
      last_name?: string;
      other_name?: string;
      customer_type?: CustomerType;
      phone?: string;
      id_no?: string;
      remarks?: string;
      code?: string;
      address?: any;
      branches?: string[];
      title?: string;
      status?: string;
      meta?: any;
      email?: string;
    }
  ): Promise<Data> => {
    try {
      return await this.put(`${this.baseURL}/customers/${id}`, param);
    } catch (err) {
      throw err;
    }
  };
  createWallet = async (customerId: string, param: any): Promise<Data> => {
    try {
      return await this.post(`${this.baseURL}/customers/${customerId}/wallets`, param);
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  withdraw = async (
    customerId: string,
    walletId: string,
    param: any
  ): Promise<Data> => {
    try {
      return await this.post(`${this.baseURL}/customers/${customerId}/wallet/${walletId}/withdraw`, param);
    } catch (err) {
      console.log(err);
      throw err;
    }
  };
  topup = async (
    customerId: string,
    walletId: string,
    param: any
  ): Promise<Data> => {
    try {
      return await this.post(`${this.baseURL}/customers/${customerId}/wallet/${walletId}/topup`, param);
    } catch (err) {
      console.log(err);
      throw err;
    }
  };
  getWallets = async (customerId: string, param: any): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/customers/${customerId}/wallets`, param);
    } catch (err) {
      throw err;
    }
  };

  getTransactions = async (
    customerId: string,
    walletId: string,
    param: any
  ): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/customers/${customerId}/wallet/${walletId}/transactions`, param);
    } catch (err) {
      throw err;
    }
  };

  uploadAttachment = async (id: string, payload: any): Promise<Data> => {
    try {

      const formData = new FormData();
      formData.append("file", payload.file);
      return await this.upload(`${this.baseURL}/customers/${id}/attachments`, formData);
    } catch (err) {
      throw err;
    }
  };
  getAttachment = async (attachmentID: string): Promise<any> => {
    try {
      return await this.getAttachment(`${this.baseURL}/attachments/${attachmentID}`);
    } catch (err) {
      throw err;
    }
  };

  getAttachments = async (id: string, query: any): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/customers/${id}/attachments`, query);
    } catch (err) {
      throw err;
    }
  };

  sendNotice = async (payload: {
    customer_id?: string;
    notice_type?: string;
    template_type?: string;
    order_type?: string;
    order_id?: string;
    branch_id?: string;
    invoice_id?: string;
    storage_id?: string;
    payment_id?: string;
    contract_id?: string;
    file_name?: string;
  }): Promise<Data> => {
    try {
      return await this.post(`${this.baseURL}/customers/${payload.customer_id}/send-notice`, payload);
    } catch (err) {
      throw err;
    }
  };
}

let _BoxappCustomerBackend: BoxappCustomerBackend | null = null;

/**
 * Returns the firebase backend
 */
const getBoxappCustomerBackend = () => {
  if (!_BoxappCustomerBackend) {
    _BoxappCustomerBackend = new BoxappCustomerBackend();
  }
  return _BoxappCustomerBackend;
};

export { getBoxappCustomerBackend };
