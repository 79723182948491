import axios from "axios";
import { BoxappBackend } from "./boxapp_helper";
import { Data, ResponseResult } from "../api/reponseResult";
import { MultiLangText } from "../models/multiLangText";

class BoxappStorageBackend extends BoxappBackend {
  getStorages = async (branchID: string | null, params: any): Promise<Data> => {
    try {

      const url = branchID ? `${this.baseURL}/branches/${branchID}/storages` : `${this.baseURL}/storages`;
      return await this.get(url, params);
    } catch (err) {
      throw err;
    }
  };
  getStorage = async (branchID: string, id: string): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/branches/${branchID}/storages/${id}`);
    } catch (err) {
      throw err;
    }
  };
  getStorageRentRecords = async (
    branchID: string,
    id: string,
    filter: any
  ): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/branches/${branchID}/storages/${id}/rent-records`, filter);
    } catch (err) {
      throw err;
    }
  };
  getStorageEvents = async (branchID: string, id: string): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/branches/${branchID}/storages/${id}/events`);
    } catch (err) {
      throw err;
    }
  };
  getStorageOrders = async (branchID: string, id: string): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/branches/${branchID}/storages/${id}/orders`);
    } catch (err) {
      throw err;
    }
  };
  createStorage = async (
    branchID: string,
    storage: {
      code: string;
      property_type: string;
      category_ids?: string[];
      feature_ids?: string[];
      monthly_price?: number;
      yearly_price?: number;
      weekly_price?: number;
      daily_price?: number;
      remarks: string;
      address: MultiLangText;
    }
  ): Promise<Data> => {
    try {
      return await this.post(`${this.baseURL}/branches/${branchID}/storages`, storage);
    } catch (err) {
      throw err;
    }
  };

  updateStorage = async (
    branchID: string,
    id: string,
    storage: {
      code: string;
      property_type: string;
      category_ids?: string[];
      feature_ids?: string[];
      monthly_price?: number;
      yearly_price?: number;
      weekly_price?: number;
      daily_price?: number;
      remarks: string;
      address: MultiLangText;
    }
  ): Promise<Data> => {
    try {
      return await this.put(`${this.baseURL}/branches/${branchID}/storages/${id}`, storage);
    } catch (err) {
      throw err;
    }
  };
  bulkUpdateStorage = async (
    branchID: string,
    storages: {
      ids: string[];
      category_ids?: string[];
      feature_ids?: string[];
      monthly_price?: number;
      yearly_price?: number;
      weekly_price?: number;
      daily_price?: number;
      property_type: string;
      remarks: string;
      address: MultiLangText;
    }
  ): Promise<Data> => {
    try {
      return await this.put(`${this.baseURL}/branches/${branchID}/storages`, storages);
    } catch (err) {
      throw err;
    }
  };

  rentStorage = async (
    branchID: string,
    id: string,
    rent: any
  ): Promise<Data> => {
    try {
      return await this.post(`${this.baseURL}/branches/${branchID}/storages/${id}/rent`, rent);
    } catch (err) {
      throw err;
    }
  };

  confirmRentStorage = async (
    branchID: string,
    id: string,
    payload: any
  ): Promise<Data> => {
    try {
      return await this.post(`${this.baseURL}/branches/${branchID}/storages/${id}/rent/confirm`, payload);
    } catch (err) {
      throw err;
    }
  };

  extendRentStorage = async (
    branchID: string,
    id: string,
    rent: any
  ): Promise<Data> => {
    try {
      return await this.post(`${this.baseURL}/branches/${branchID}/storages/${id}/extend`, rent);
    } catch (err) {
      throw err;
    }
  };

  terminateRentStorage = async (
    branchID: string,
    id: string,
    rent: {
      order_id: string;
      remarks: string;
      customer_id: string;
      move_out_date: number;
      termination_date: number;
    }
  ): Promise<Data> => {
    try {
      return await this.post(`${this.baseURL}/branches/${branchID}/storages/${id}/terminate`, rent);
    } catch (err) {
      throw err;
    }
  };
  moveOut = async (branchID: string, id: string, param: any): Promise<Data> => {
    try {
      return await this.post(`${this.baseURL}/branches/${branchID}/storages/${id}/move-out`, param);
    } catch (err) {
      throw err;
    }
  };
  maintenanceStorage = async (branchID: string, id: string): Promise<Data> => {
    try {
      return await this.post(`${this.baseURL}/branches/${branchID}/storages/${id}/maintenance`, {});
    } catch (err) {
      throw err;
    }
  };
  releaseStorage = async (
    branchID: string,
    id: string,
    payload: any
  ): Promise<Data> => {
    try {
      return await this.post(`${this.baseURL}/branches/${branchID}/storages/${id}/release`, payload);
    } catch (err) {
      throw err;
    }
  };
  resumeStorage = async (branchID: string, id: string): Promise<Data> => {
    try {
      return await this.post(`${this.baseURL}/branches/${branchID}/storages/${id}/resume`, {});
    } catch (err) {
      throw err;
    }
  };

  closeStorage = async (branchID: string, id: string): Promise<Data> => {
    try {
      return await this.post(`${this.baseURL}/branches/${branchID}/storages/${id}/close`, {});
    } catch (err) {
      throw err;
    }
  };

  cancel = async (
    branchID: string,
    id: string,
    payload: any
  ): Promise<Data> => {
    try {
      return await this.post(`${this.baseURL}/branches/${branchID}/storages/${id}/cancel`, payload);
    } catch (err) {
      throw err;
    }
  };
}

let _BoxappStorageBackend: BoxappStorageBackend | null = null;

/**
 * Returns the firebase backend
 */
const getBoxappStorageBackend = () => {
  if (!_BoxappStorageBackend) {
    _BoxappStorageBackend = new BoxappStorageBackend();
  }
  return _BoxappStorageBackend;
};

export { getBoxappStorageBackend };
