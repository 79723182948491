import React, { useEffect, useState, useMemo, useCallback } from "react";

import "./invoiceInfo.scss";
import moment from "moment-timezone";
import RentalPeriod, {
  RentPeriod,
} from "../../../components/Modal/Storages/RentalPeriod";

import { Alert, Row, Col, Input, Label, Card, CardBody } from "reactstrap";

// Redux
import SubmissionModal from "../../../components/Modal/SubmissionModal";

import { useTranslation } from "react-i18next";
import { Order } from "src/models/order";
import { Storage } from "src/models/storage";
import { Branch } from "src/models/branch";
import { MultiLangText } from "src/models/multiLangText";
import { PaidItem } from "src/models/paidItem";
import { calPrice } from "./invoicePaidItem";
import { InvoiceType } from "src/models/invoice";
import { OrderType } from "src/util/orderType";

export interface StoragePaidItemProps {
  id?: string;
  order?: Order;
  branch: Branch;
  storage: Storage;
  modal: boolean;
  item?: PaidItem;
  invoiceType: InvoiceType;
  orderType: OrderType;
  onSuccess: (paidItem: PaidItem) => void;
  onFailed: () => void;
  onClose: () => void;
}

export const StoragePaidItem = (props: StoragePaidItemProps) => {
  const { i18n } = useTranslation();
  const [startDate, setStartDate] = useState<number>();
  const [endDate, setEndDate] = useState<number>();
  const [originalPrice, setOriginalPrice] = useState<number>(0);
  const [rentType, setRentType] = useState<"YEAR" | "MONTH" | "WEEK" | "DAY">(
    "YEAR"
  );
  const [rentPeriod, setRentPeriod] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState<string>();
  useEffect(() => {
    if (props.item) {
      setOriginalPrice(Number(props.item?.original_price) ?? 0);
      setRentType(props.item?.rent_type ?? "YEAR");
      setRentPeriod(Number(props.item?.rent_period ?? 1));
      setStartDate(props.item?.start_date ?? 0);
      setEndDate(props.item?.end_date ?? 0);
    }
  }, [props.item]);

  const onSubmit = async () => {
    const discount_amount = 0;

    if ((originalPrice ?? 0) < 0) {
      setErrorMessage(i18n.t("paid item price is invalid"));
      return;
    }

    setErrorMessage(undefined);

    props.onSuccess({
      id: props.item?.id,
      order_id: props.order?.id,
      order_type: props.order?.order_type,
      item_id: props.storage?.id,
      item_type: "STORAGE",
      item_name: {
        en: props.storage?.code,
        zh: props.storage?.code,
        cn: props.storage?.code,
      } as MultiLangText,
      item: props.storage as Storage,
      start_date: startDate,
      end_date: endDate,
      rent_period: rentPeriod,
      rent_type: rentType,
      final_price: originalPrice - discount_amount,
      original_price: originalPrice,
      discount_amount: discount_amount,
      status: "DRAFT",
      
                    sort: props.item?.sort ?? 0,
    });
  };

  const onClose = () => {
    props.onClose();
  };

  return (
    <SubmissionModal
      id={props.id}
      size={"xl"}
      onSubmit={onSubmit}
      onClose={onClose}
      title={`${i18n.t("Storage Supplement Item")}`}
      show={props.modal ?? false}
    >
      <Card className="border">
        <CardBody>
          <Row className="ml-2 mb-1">
            <Col xs="6">
              <Label>
                {i18n.t("Rental Location")}-{props.storage?.code}
              </Label>
            </Col>
            <Col xs="5">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">$</span>
                </div>
                <Input
                  type="number"
                  maxLength={8}
                  name={"paidItemPrice"}
                  onChange={(e) => {
                    setOriginalPrice(Number(e.target.value ?? 0));
                  }}
                  value={originalPrice}
                />
              </div>
            </Col>
            <Col xs="1"></Col>

            {props.orderType === "Rent" && props.invoiceType === "Payment" && (
              <Col xs="12" className="mt-2">
                <RentalPeriod
                  defaultRentType={rentType}
                  rentPeriod={rentPeriod}
                  startDate={startDate ? Number(startDate) : moment().unix()}
                  endDate={endDate}
                  periodTitle={i18n.t("Rent")}
                  dateRequired={
                    props.order?.order_type === "Deposit" ? false : true
                  }
                  allow_yearly={
                    !!(
                      props.branch?.allow_yearly && props.storage?.yearly_price
                    )
                  }
                  allow_monthly={
                    !!(
                      props.branch?.allow_monthly &&
                      props.storage?.monthly_price
                    )
                  }
                  allow_weekly={
                    !!(
                      props.branch?.allow_weekly && props.storage?.weekly_price
                    )
                  }
                  allow_daily={
                    !!(props.branch?.allow_daily && props.storage?.daily_price)
                  }
                  onChange={(e: RentPeriod) => {
                    setRentType(e.rent_type ?? "YEAR");
                    setRentPeriod(e.rent_period ?? 1);
                    setStartDate(e.start_date);
                    setEndDate(e.end_date);
                    setOriginalPrice(
                      calPrice(
                        {
                          rent_type: e.rent_type ?? "YEAR",
                          rent_period: e.rent_period ?? 1,
                        },
                        props.storage
                      )
                    );
                  }}
                />
              </Col>
            )}
          </Row>
        </CardBody>
      </Card>
      {errorMessage && (
        <Row>
          <Col>
            <Alert
              color="danger"
              className="alert-dismissible fade show  mb-4 pt-2 font-size-13"
              role="alert"
            >
              {errorMessage}
            </Alert>
          </Col>
        </Row>
      )}
    </SubmissionModal>
  );
};

export default StoragePaidItem;
