import React, { useState, useEffect, useCallback, useMemo } from "react";
import { withTranslation } from "react-i18next";
import {
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  InputGroup,
  Col,
  Row,
  Button,
  Card,
  CardBody,
  CardSubtitle,
} from "reactstrap";
import { BxButton } from "../../Button";

import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import "./storage.scss";
// Redux
import { MODAL_TYPES } from "../ModalProps";

import { useGlobalModalContext } from "../../../context/GlobalModalContext";

import { useBranchContext } from "../../../context/BranchProviderContext";
import { useStorageContext } from "../../../context/StorageProviderContext";
import { useTranslation } from "react-i18next";
import { getBoxappStorageBackend } from "src/helpers/boxapp_storage_helper";
import { DepositOrder } from "src/models/depositOrder";
import { Customer } from "src/models/customer";
import { Order } from "src/models/order";
import StorageTerminateContractPanel from "./StorageTerminateContractPanel";
import StorageTerminateRefundPanel from "./StorageTerminateRefundPanel";
import { Data } from "src/api/reponseResult";
import { getBoxappInvoiceBackend } from "src/helpers/boxapp_invoice_helper";
import { InvoiceType } from "src/models/invoice";
import { OrderType } from "src/util/orderType";
import { PaidItem } from "src/models/paidItem";

interface StorageTerminateProps extends RouteComponentProps {
  modal: boolean;
  mode: string;
  order: Order;
  customer: Customer;
  depositOrder: DepositOrder;
  onClose: () => void;
  onSuccess: (mode: string, storage: any) => void;
}
export enum Terminate {
  Contract = "Terminate Contract",
  Refund = "Deposit Refund",
}
const StorageTerminate = (props: StorageTerminateProps) => {
  const { contextStorage } = useStorageContext();
  const { i18n } = useTranslation();
  const { showModal } = useGlobalModalContext();
  const [terminateDate, setTerminateDate] = useState(new Date());
  const [moveOutDate, setMoveOutDate] = useState(new Date());
  const { contextBranch } = useBranchContext();

  const [currentStep, setCurrentStep] = useState<number>(0);

  const [requestBody, setRequestBody] = useState<Map<string, any>>(new Map());
  const [submitButtonBlock, setSubmitButtonBlock] = useState(false);
  const [process, setProcess] = useState<number[]>([]);
  const [paidItems, setPaidItems] = useState<any[]>([]);
  useEffect(() => {
    if (props.depositOrder) {
      setPaidItems(
        props.depositOrder?.invoices
          ?.flatMap((e) => e?.paid_items ?? [])
          ?.filter((x) => x) ?? []
      );
    }
  }, [props.depositOrder]);
  const steps = useMemo(() => {
    if (props.depositOrder) {
      return [Terminate.Contract, Terminate.Refund];
    }
    return [Terminate.Contract];
  }, [props.depositOrder]);

  const togglemodal = () => {
    setTerminateDate(new Date());
    setMoveOutDate(new Date());
    props.onClose();
  };
  const isFinished = useCallback(
    (i: number) => process?.indexOf(i) > -1,
    [process]
  );

  const formValid = useMemo(() => {
    if (steps.length === 1) {
      return true;
    }
    return process.length >= steps.length;
  }, [process]);
  // useEffect(() => {
  //   if (status === SUCCESS) {
  //     if (response.storage) {
  //       props.onSuccess(props.mode, response.storage);
  //     }
  //   }
  // }, [status, response]);

  const submit = async () => {
    setSubmitButtonBlock(true);
    const interval = 2000;
    const timeoutId = setTimeout(() => {
      setSubmitButtonBlock(false);
      clearTimeout(timeoutId);
    }, interval);

    if (!props.customer) {
      return;
    }
    if (!moveOutDate) {
      return;
    }
    showModal(MODAL_TYPES.ACTION_MODAL, {
      title: i18n.t("Are you Confirm?"),
      message: i18n.t(`The termination cannot resume`),
      onConfirm: () => {
        showModal(MODAL_TYPES.REMOVAL_ACTION_MODAL, {
          title: i18n.t("Confirm to terminate"),
          onConfirm: async () => {
            try {
              //terminate 
              const { storage } = await terminate(requestBody.get(steps[0]));
              if (!storage) {
                throw new Error("terminate failed");
              }
              if (storage) {
                showModal(MODAL_TYPES.ALERT_MODAL, {
                  title: i18n.t("Terminate Success"),
                  messageType: "success",
                  message: i18n.t("Terminate Success"),
                });
                props.onSuccess(props.mode, storage);
              }
            } catch (e) {
              const errorMsg =
                (e as any)?.data?.detail ??
                "" + (e as any)?.data?.message ??
                "" + e?.toString();
              showModal(MODAL_TYPES.ALERT_MODAL, {
                title: i18n.t("rent rental location failed"),
                messageType: "error",
                message: errorMsg,
              });
            }
          },
        });
      },
    });
  };

  const terminate = async (param: {
    order_id: string;
    remarks: string;
    customer_id: string;
    move_out_date: number;
    termination_date: number;
  }): Promise<Data> => {
    if (!terminateDate) {
      throw new Error("Terminate date is required");
    }
    if (!contextBranch?.id) {
      throw new Error("Branch is required");
    }

    if (!contextStorage?.id) {
      throw new Error("Storage is required");
    }
    return await getBoxappStorageBackend().terminateRentStorage(
      contextBranch?.id,
      contextStorage?.id,
      param
    );
  };

  return (
    <React.Fragment>
      <Modal
        size="lg"
        isOpen={props.modal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className=""
        tabIndex={-1}
        toggle={togglemodal}
      >
        <div className="modal-content">
          <ModalHeader toggle={togglemodal}>
            {i18n.t("Contract Terminate")}
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                <FormGroup>
                  <div className="mt-4">
                    {/* <h5 className="font-size-14 mb-4">{i18n.t("Rent Mode")}</h5> */}
                    <Row>
                      {steps.map((e, i) => {
                        return (
                          <Col
                            key={i}
                            md={4}
                            className="btn"
                            onClick={() => {
                              setCurrentStep(i);
                            }}
                          >
                            <div
                              className={`avatar-sm rounded-circle  align-self-center mini-stat-icon float-left `}
                            >
                              <span
                                className={`avatar-title rounded-circle  ${currentStep === steps.length - 1 && formValid
                                  ? "bg-success"
                                  : currentStep === i
                                    ? "bg-primary"
                                    : isFinished(i)
                                      ? "bg-success"
                                      : "bg-light "
                                  } `}
                              >
                                {i + 1}
                              </span>
                            </div>
                            <div className="float-left h-100 ml-4 align-items-center ">
                              <h5 className="pt-3 h-100 justify-content-center align-items-center  font-size-14 mb-1 text-dark">
                                {i18n.t(e)}
                              </h5>
                            </div>
                            {steps?.length - 1 !== i && (
                              <i
                                className={`bx bx-right-arrow-alt  pt-3 font-size-22  ${currentStep === i
                                  ? "text-primary"
                                  : isFinished(i)
                                    ? "text-success"
                                    : "text-light"
                                  }`}
                              ></i>
                            )}
                          </Col>
                        );
                      })}
                    </Row>
                  </div>
                </FormGroup>
              </Col>
            </Row>

            <StorageTerminateContractPanel
              totalSteps={steps.length}
              // panel={step}
              step={0}
              onSubmit={async (e: any) => {
                console.log(e);
                if (e) {
                  setRequestBody(
                    requestBody.set(steps[0], {
                      ...e,
                      storage_id: contextStorage?.id,
                      branch_id: contextBranch?.id,
                    })
                  );
                  setCurrentStep(0 + 1);
                  setProcess([...process, 0]);
                  if (!props.depositOrder) {
                    submit();
                  }
                }
              }}
              mode={props.mode}
              isFinished={isFinished(0)}
              onCancel={() => {
                setProcess(process.filter((p) => p !== 0));
              }}
              open={props.depositOrder ? currentStep === 0 : currentStep >= 0}
              order={props.order}
              customer={props.customer}
              depositOrder={props.depositOrder}
            />
            {props.depositOrder && (
              <StorageTerminateRefundPanel
                // rentMode={RentMode.NewMasterContract}
                totalSteps={steps.length}
                // panel={step}
                step={1}
                onSubmit={async (e: {
                  order_type: OrderType;
                  final_price: number;
                  remarks: string;
                  paid_items: PaidItem[];
                }) => {
                  if (e) {
                    paidItems?.map((paidItem) => {
                      const newPaidItem = e?.paid_items?.find(
                        (x) => x?.id === paidItem?.id
                      );
                      return newPaidItem ?? paidItem;
                    });
                    setRequestBody(requestBody.set(steps[1], e));
                    // setCurrentStep(1 + 1);
                  }
                  setProcess([...process, 1]);
                }}
                // contract={contract}
                // customer={customer!}
                // branch={contextBranch!}
                storage={contextStorage}
                // mode={props.mode}
                isFinished={isFinished(1)}
                onCancel={() => {
                  setProcess(process.filter((p) => p !== 1));
                }}
                open={currentStep === 1}
                branch={contextBranch}
                mode={props.mode}
                order={props.depositOrder}
                paidItems={paidItems}
              // startDate={moment().unix()}
              // orderDate={moment().unix()}
              // paidItems={[]}
              />
            )}
            {props.depositOrder && currentStep === steps.length - 1 && (
              <BxButton
                loading={submitButtonBlock}
                disabled={!formValid || submitButtonBlock}
                className="btn"
                type="button"
                color="success"
                onClick={submit}
              >
                {i18n.t("Submit")}
              </BxButton>
            )}
          </ModalBody>
          <ModalFooter></ModalFooter>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default withRouter(withTranslation()(StorageTerminate));
