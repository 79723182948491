import axios from "axios";
import { BoxappBackend } from "./boxapp_helper";
import { ExpendType } from "../models/expendType";
import { Data, ResponseResult } from "../api/reponseResult";
import { ExpendTypeCategory } from "../models/expendTypeCategory";
import { store } from "src/util/storage";

class BoxappExpendTypeBackend extends BoxappBackend {
  createExpendType = async (expendType: ExpendType): Promise<Data> => {
    try {
      return await this.post(`${this.baseURL}/expendTypes`, expendType);
    } catch (err) {
      throw err;
    } finally {
      store.removeItem("expendTypes");
    }
  };

  updateExpendType = async (
    id: string,
    expendType: ExpendType
  ): Promise<Data> => {
    try {
      return await this.put(`${this.baseURL}/expendTypes/${id}`, expendType);
    } catch (err) {
      throw err;
    } finally {
      store.removeItem("expendTypes");
    }
  };
  getExpendTypes = async (): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/expendTypes`);
    } catch (err) {
      throw err;
    }
  };
  getExpendType = async (id: string): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/expendTypes/${id}`);
    } catch (err) {
      throw err;
    }
  };
  createExpendTypeCategory = async (
    expendTypeCategory: ExpendTypeCategory
  ): Promise<Data> => {
    try {
      return await this.post(`${this.baseURL}/expendTypeCategories`, expendTypeCategory);
    } catch (err) {
      throw err;
    } finally {
      store.removeItem("expendTypes");
    }
  };
  getExpendTypeCategories = async (): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/expendTypeCategories`);
    } catch (err) {
      throw err;
    }
  };
}

let _BoxappExpendTypeBackend: BoxappExpendTypeBackend | null = null;

/**
 * Returns the firebase backend
 */
const getBoxappExpendTypeBackend = () => {
  if (!_BoxappExpendTypeBackend) {
    _BoxappExpendTypeBackend = new BoxappExpendTypeBackend();
  }
  return _BoxappExpendTypeBackend;
};

export { getBoxappExpendTypeBackend };
