import React, { useEffect, useState, useCallback, useMemo } from "react";
import { FormGroup, Label, InputGroup, Col, Row, Button } from "reactstrap";

import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
// Redux

export interface PaidItemPeriodProps {
  startDate?: number;
  endDate?: number;
  dateRequired?: boolean;
  disabled?: boolean;
  name?: string;
  onChange?: (data: { start_date?: number; end_date?: number }) => void;
}
const PaidItemPeriod = (props: PaidItemPeriodProps) => {
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date | null>();
  const { i18n } = useTranslation();
  const cleanDates = () => {
    setStartDate(undefined);
    setEndDate(undefined);
    if (props.onChange) {
      props.onChange({
        start_date: undefined,
        end_date: undefined
      });
    }
  };


  useEffect(() => {
    if (props?.startDate) {
      setStartDate(moment.unix(props.startDate).toDate());
    }
    if (props.endDate) {
      setEndDate(moment.unix(props.endDate).toDate());
    }
  }, [props.startDate, props.endDate]);

  useEffect(() => {
    if (props.onChange && startDate) {
      props.onChange({
        start_date: startDate
          ? moment(startDate).startOf("day").unix()
          : undefined,
        end_date: endDate ? moment(endDate).startOf("day").unix() : undefined,
      });
    }
  }, [startDate, endDate]);

  const errorMsg = useMemo(() => {
    if (props.dateRequired) {
      if (!endDate) {
        return i18n.t("end date must be entered");
      }
      if (startDate && endDate < startDate)
        return i18n.t("end date must later than start date");
    }
  }, [endDate]);

  return (
    <Row className="mb-1">
      <Col xs="12">
        <Label htmlFor="validationRentPeriod">
          {i18n.t("Pay Used period")}
        </Label>
      </Col>
      <Col xs="12" sm="6">
        <FormGroup>
          <Label>{`${i18n.t("Start Date")}`}</Label>
          <InputGroup>
            <DatePicker
              placeholderText={!props.dateRequired ? i18n.t("Optional") : ""}
              dateFormat="yyyy-MM-dd"
              selected={startDate}
              name={props.name + "-startDate"}
              className="form-control"
              onChange={(date: Date) => setStartDate(date)}
              showYearDropdown
            />
          </InputGroup>
        </FormGroup>
        <Button
          className="ml-2 bg-info"
          onClick={cleanDates}
        >
          <i className="bx bxs-eraser font-size-15 mr-2" />
          {`${i18n.t("Clean All")}${i18n.language === "en" ? " " : ""}${i18n.t("Date")}`}
        </Button>
      </Col>
      <Col xs="12" sm="6">
        <FormGroup>
          <Label>{`${i18n.t("End Date")}`}</Label>
          <InputGroup>
            <DatePicker
              placeholderText={!props.dateRequired ? i18n.t("Optional") : ""}
              dateFormat="yyyy-MM-dd"
              name={props.name + "-endDate"}
              selected={endDate}
              className="form-control"
              onChange={(date: Date) => setEndDate(date)}
              showYearDropdown
            />
            <span className="failed">{errorMsg}</span>
          </InputGroup>
        </FormGroup>
      </Col>
    </Row>
  );
};

export default PaidItemPeriod;
