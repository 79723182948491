import axios from "axios";
import { BoxappBackend } from "./boxapp_helper";
import { Data, ResponseResult } from "../api/reponseResult";
import { Order } from "../models/order";
import { Payment } from "../models/payment";
import { DepositOrder } from "../models/depositOrder";
import { InvoiceType } from "src/models/invoice";
import { OrderType } from "src/util/orderType";
export interface CreateOrderRequest extends Order {
  invoice_remarks: string;
}
export interface CreateDepositOrderRequest extends DepositOrder {
  invoice_remarks: string;
}
export interface GetOrdersRequest {
  customer_id?: string;
  contract_id?: string;
  branch_id?: string;
  order_type?: string;
  status?: string;
  created_by?: string;
  last_edited_date?: number;
  order_start_date?: number;
  order_end_date?: number;
  order_start_date_from?: number;
  order_start_date_to?: number;
  order_end_date_from?: number;
  order_end_date_to?: number;
  paid_amount_from?: number;
  paid_amount_to?: number;
  account_receivable_from?: number;
  account_receivable_to?: number;
  limit?: number;
  sort?: string;
  page?: number;

}
class BoxappOrderBackend extends BoxappBackend {
  //deposit
  createDepositOrder = async (
    order: CreateDepositOrderRequest
  ): Promise<Data> => {
    try {
      return await this.post(`${this.baseURL}/orders?orderType=Deposit`, order);
    } catch (err) {
      throw err;
    }
  };

  //order
  createOrder = async (order: CreateOrderRequest): Promise<Data> => {
    try {
      return await this.post(`${this.baseURL}/orders?orderType=Rent`, order);
    } catch (err) {
      throw err;
    }
  };
  getDeposits = async (query: any): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/orders`, {
        ...query,
        order_type: "Deposit",
      });
    } catch (err) {
      throw err;
    }
  };
  getDeposit = async (id: string): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/orders/${id}?orderType=Deposit`);

    } catch (err) {
      throw err;
    }
  };
  getOrders = async (query: GetOrdersRequest): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/orders`, query);
    } catch (err) {
      throw err;
    }
  };
  getOrder = async (id: string, orderType?: string): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/orders/${id}${orderType ? `?orderType=${orderType}` : ""}`);
    } catch (err) {
      throw err;
    }
  };

  getOrderEvents = async (id: string, query: any): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/orders/${id}/events`, query);
    } catch (err) {
      throw err;
    }
  };
  updateOrder = async (
    id: string,
    orderType: string,
    param: {
      remarks?: string;
      status?: string;
      actual_start_date?: number;
      actual_end_date?: number;
      start_date?: number;
      end_date?: number;
    }
  ): Promise<Data> => {
    try {
      return await this.put(`${this.baseURL}/orders/${id}?orderType=${orderType}`, param);
    } catch (err) {
      throw err;
    }
  };
  updateOrderBranch = async (id: string, orderType: string): Promise<Data> => {
    try {
      return await this.put(`${this.baseURL}/orders/${id}/branch?orderType=${orderType}`, {});
    } catch (err) {
      throw err;
    }
  };
  updateOrderCustomer = async (
    id: string,
    orderType: string
  ): Promise<Data> => {
    try {
      return await this.put(`${this.baseURL}/orders/${id}/customer?orderType=${orderType}`, {});
    } catch (err) {
      throw err;
    }
  };
  createPayment = async (
    id: string,
    payment: {
      invoice_type: InvoiceType;
      order_type: OrderType;
      ref_no?: string;
      payment_amount: number;
      remarks?: string;
      payment_method: string;
      payment_date: number;
      wallet_id?: string;
      wallet_payment_amount: number;
    }
  ): Promise<Data> => {
    try {
      return await this.post(`${this.baseURL}/invoices/${id}/payments`, payment);
    } catch (err) {
      throw err;
    }
  };
  uploadAttachment = async (id: string, payload: any): Promise<Data> => {
    try {
      const formData = new FormData();
      formData.append("file", payload.file);
      formData.append("orderType", payload.orderType);
      return await this.upload(`${this.baseURL}/orders/${id}/attachments`, formData);
    } catch (err) {
      throw err;
    }
  };
  getOrderAttachment = async (attachmentID: string): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/attachments/${attachmentID}`);
    } catch (err) {
      throw err;
    }
  };

  getOrderAttachments = async (
    id: string,
    orderType: string,
    query: any
  ): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/orders/${id}/attachments?orderType=${orderType}`, query);
    } catch (err) {
      throw err;
    }
  };
  getReceipt = async (
    id: string,
    receiptType: string,
    lng: string
  ): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/orders/${id}/receipt`, {
        receipt_type: receiptType,
        language: lng,
      });
    } catch (err) {
      throw err;
    }
  };
  void = async (
    id: string,
    param: {
      remarks: string;
    }
  ): Promise<Data> => {
    try {
      return await this.post(`${this.baseURL}/orders/${id}/void`, param);
    } catch (err) {
      console.log(err);
      throw err;
    }
  };
}

let _BoxappOrderBackend: BoxappOrderBackend | null = null;

/**
 * Returns the firebase backend
 */
const getBoxappOrderBackend = () => {
  if (!_BoxappOrderBackend) {
    _BoxappOrderBackend = new BoxappOrderBackend();
  }
  return _BoxappOrderBackend;
};

export { getBoxappOrderBackend };
