import axios from "axios";
import { BoxappBackend } from "./boxapp_helper";
import { Data, ResponseResult } from "../api/reponseResult";
class BoxappReportBackend extends BoxappBackend {
  monthlyReceiptReport = async (params: any): Promise<any> => {
    try {
      return await this.post(`${this.baseURL}/reports/monthly/receipt`, params);
    } catch (err) {
      throw err;
    }
  };
  monthlyReceiptTotalReport = async (params: any): Promise<any> => {
    try {
      return await this.post(`${this.baseURL}/reports/monthly/receipt-total`, params);
    } catch (err) {
      throw err;
    }
  };
  monthlyDepositSummaryReport = async (params: any): Promise<any> => {
    try {
      return await this.post(`${this.baseURL}/reports/monthly/deposit-summary`, params);
    } catch (err) {
      throw err;
    }
  };

  monthlyPropertyReport = async (params: any): Promise<any> => {
    try {
      return await this.post(`${this.baseURL}/reports/monthly/property`, params);
    } catch (err) {
      throw err;
    }
  };
  monthlyPropertyTotalReport = async (params: any): Promise<any> => {
    try {
      return await this.post(`${this.baseURL}/reports/monthly/property-total`, params);
    } catch (err) {
      throw err;
    }
  };
  orderDetailReport = async (params: any): Promise<any> => {
    try {
      return await this.post(`${this.baseURL}/reports/orders/detail`, params);
    } catch (err) {
      throw err;
    }
  };
  orderDetailTotalReport = async (params: any): Promise<any> => {
    try {
      return await this.post(`${this.baseURL}/reports/orders/detail-total`, params);
    } catch (err) {
      throw err;
    }
  };
  depositDetailReport = async (params: any): Promise<any> => {
    try {
      return await this.post(`${this.baseURL}/reports/deposit-orders/detail`, params);
    } catch (err) {
      throw err;
    }
  };
  depositDetailTotalReport = async (params: any): Promise<any> => {
    try {
      return await this.post(`${this.baseURL}/reports/deposit-orders/detail-total`, params);
    } catch (err) {
      throw err;
    }
  };
  storagesReport = async (params: any): Promise<any> => {
    try {
      return await this.post(`${this.baseURL}/reports/storages`, params);
    } catch (err) {
      throw err;
    }
  };

  ordersReport = async (params: any): Promise<any> => {
    try {
      return await this.post(`${this.baseURL}/reports/orders`, params);
    } catch (err) {
      throw err;
    }
  };
  contractsReport = async (params: any): Promise<any> => {
    try {
      return await this.post(`${this.baseURL}/reports/contracts`, params);
    } catch (err) {
      throw err;
    }
  };
  monthlyRentalReport = async (params: any): Promise<any> => {
    try {
      return await this.post(`${this.baseURL}/reports/monthly/rental`, params);
    } catch (err) {
      throw err;
    }
  };
  monthlyDepositReport = async (params: any): Promise<any> => {
    try {
      return await this.post(`${this.baseURL}/reports/monthly/deposit`, params);
    } catch (err) {
      throw err;
    }
  };
  monthlyDepositSettledReport = async (params: any): Promise<any> => {
    try {
      return await this.post(`${this.baseURL}/reports/monthly/deposit/settled`, params);
    } catch (err) {
      throw err;
    }
  };
}

let _BoxappReportBackend: BoxappReportBackend | null = null;

/**
 * Returns the firebase backend
 */
const getBoxappReportBackend = () => {
  if (!_BoxappReportBackend) {
    _BoxappReportBackend = new BoxappReportBackend();
  }
  return _BoxappReportBackend;
};

export { getBoxappReportBackend };
