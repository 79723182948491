import { Invoice, InvoiceType } from "src/models/invoice";
import { Storage } from "../../models/storage";
import { Payment } from "src/models/payment";
import { Order } from "src/models/order";
import { Customer } from "src/models/customer";
import { DepositOrder } from "src/models/depositOrder";
import { MasterContract, masterContractStatusBadge } from 'src/models/masterContract';
export enum STORAGE_DIALOG_TYPES {
  RENT_MODAL = "RENT_MODAL",
  RENT_NEW_CONTRACT_MODAL = "RENT_NEW_CONTRACT_MODAL",
  RENT_NEW_MASTER_CONTRACT_MODAL = "RENT_NEW_MASTER_CONTRACT_MODAL",
  RENT_NEW_ORDER_MODAL = "RENT_NEW_ORDER_MODAL",
  RENT_NEW_DEPOSIT_MODAL = 'RENT_NEW_DEPOSIT_MODAL',
  PAYMENT_MODAL = "PAYMENT_MODAL",
  CANCEL_MODAL = "CANCEL_MODAL",
  CURRENT_VIEW_MODAL = "CURRENT_VIEW_MODAL",
  RELEASE_MODAL = "RELEASE_MODAL",
  SEARCH_MODAL = "SEARCH_MODAL",
  TERMINATE_MODAL = "TERMINATE_MODAL",
  MOVE_OUT_MODAL = "MOVE_OUT_MODAL",
}

export interface StorageModalComponent {
  modalType?: STORAGE_DIALOG_TYPES;
  modalProps?:
  | StorageModalProps
  | StorageSearchModalProps
  | StoragePaymentModalProps
  | StorageRentModalProps;
  modalShow: boolean;
}

export interface StoragePaymentModalProps {
  mode: string;
  invoice: Invoice;
  order: Order | DepositOrder;
  onPaidSuccess: (payment: Payment) => void;
  onClose: () => void;
  onSuccess: (mode: string, payload: any) => void;

  className?: string;
  children?: React.ReactNode;
}

export interface StorageModalProps {
  modal?: boolean;
  mode: string;
  storage?: Storage;
  branchId?: string;
  order: Order;
  customer: Customer;
  newOrder?: Order;
  depositOrder: DepositOrder;
  masterContractID: string;
  onClose: () => void;
  onSuccess: (mode: string, payload: any) => void;
  id?: string;
  show?: boolean;
  hideFooter?: boolean;
  className?: string;
  children?: React.ReactNode;
}

export interface StorageRentModalProps {
  modal?: boolean;
  mode: string;
  storage?: Storage;
  masterContract?: MasterContract
  customer: Customer;
  onClose: () => void;
  onSuccess: (mode: string, payload: any) => void;
  id?: string;
  show?: boolean;
  hideFooter?: boolean;
  className?: string;
  children?: React.ReactNode;
}

export interface StorageSearchModalProps {
  modal?: boolean;
  branchId?: string;
  onSearched: (storage: Storage) => void;
  onClose: () => void;
  show?: boolean;
  hideFooter?: boolean;
  className?: string;
  children?: React.ReactNode;
}
