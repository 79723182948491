import React, { lazy, useEffect, useState, useCallback, useMemo } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Col,
  FormGroup,
  Row,
} from "reactstrap";
import { withTranslation, useTranslation } from "react-i18next";
import "./storageRent.scss";
import ReactTooltip from "react-tooltip";
import { useStorageContext } from "../../../context/StorageProviderContext";

import { AvForm } from "availity-reactstrap-validation";
import { Customer } from "src/models/customer";
import { BxButton } from "src/components/Button";
import { Storage } from "src/models/storage";

export interface StorageRentProps {
  mode: string;
  onSuccess: (rentMode: RentMode) => void;
  onClose: () => void;
  modal: boolean;
  customer: Customer;
  interval?: number;
  storage?: Storage;
}
export enum RentPanelSteps {
  Contract = "Contract",
  Order = "Order",
  Deposit = "Deposit",
}
export enum RentMode {
  NewMasterContract = "New Master Contract",
  NewContract = "Renew Contract",
  ContinueRent = "Next Rent",
}
const StorageRent = (props: StorageRentProps) => {
  const { i18n } = useTranslation();
  const { contextStorage, setContextStorage } = useStorageContext();
  const [rentMode, setRentMode] = useState<RentMode>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const rentModeList = useMemo(() => {
    return [
      {
        id: 3,
        name: RentMode.ContinueRent,
        disabled: !props.customer,
      },
      {
        id: 2,
        name: RentMode.NewContract,
        disabled: !props.customer,
      },
      {
        id: 1,
        name: RentMode.NewMasterContract,
        disabled: false,
      },
    ];
  }, []);

  useEffect(() => {
    if (props.storage) {
      setContextStorage(props.storage);
    }
  }, [props.storage]);

  const togglemodal = () => {
    props.onClose();
  };

  return !contextStorage ? null : (
    <React.Fragment>
      <Modal
        size="lg"
        isOpen={props.modal}
        role="dialog"
        backdrop="static"
        autoFocus={true}
        centered={true}
        className="rent"
        tabIndex={-1}
        toggle={togglemodal}
      >
        <div className="modal-content">
          <ModalHeader toggle={togglemodal}>
            {i18n.t("Rent Storage")}
          </ModalHeader>
          <div className="ml-3">
            <h5 className="mt-3 font-13">
              {" "}
              {i18n.t("Rental Location Number")} - {contextStorage?.code}
            </h5>
          </div>
          <ModalBody>
            <AvForm
              action="#"
              onSubmit={() => {
                setErrorMessage(undefined);
                if (rentMode) {
                  props.onSuccess(rentMode);
                } else {
                  setErrorMessage(i18n.t("Please select rent mode"));
                }
              }}
            >
              <Row>
                <Col>
                  <FormGroup>
                    <div className="mt-4">
                      <h5 className="font-size-14 mb-4">
                        {i18n.t("Rent Mode")}
                        <a data-tip data-for="tipsRentMode" className="ml-2">
                          {i18n.t("ⓘ")}
                        </a>
                        <ReactTooltip id="tipsRentMode" effect="solid">
                          <span>
                            <p>
                              {i18n.t(
                                "Next Rent - The same customer rent the next phase."
                              )}
                            </p>
                            <p>
                              {i18n.t(
                                "Renew Contract - The same customer renew the Contract."
                              )}
                            </p>
                            <p>
                              {i18n.t(
                                "New Master Contract - Create the new customer in this property."
                              )}
                            </p>
                          </span>
                        </ReactTooltip>
                      </h5>
                      <Row>
                        {rentModeList?.map((item, i) => (
                          <Col key={i} xs={12 / rentModeList?.length}>
                            <BxButton
                              id={`rentMode${item.id}`}
                              disabled={item.disabled}
                              className="mb-3 btn btn-block"
                              type="button"
                              color={
                                rentMode === item.name ? "warning" : "light"
                              }
                              onClick={() => setRentMode(item.name)}
                            >
                              {i18n.t(item.name)}
                            </BxButton>
                          </Col>
                        ))}
                        <Col className="error">{errorMessage}</Col>
                      </Row>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <BxButton className="mr-2" type="submit" color="success">
                {i18n.t("Submit")}
              </BxButton>
              <Button type="button" color="secondary" onClick={togglemodal}>
                {i18n.t("Close")}
              </Button>
            </AvForm>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default withTranslation()(StorageRent);
