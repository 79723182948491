import axios from "axios";
import { BoxappBackend } from "./boxapp_helper";
import { Data, ResponseResult } from "../api/reponseResult";
import { Customer } from "../models/customer";
import { Branch } from "../models/branch";
import { Storage } from "../models/storage";
import { Transaction } from "../models/transaction";
import { PaidItem } from "../models/paidItem";

interface GenTemplateParam {
  // template_type: string;
  // branch_id: string;
  id: string;
  data:
  | ContractTemplateParam
  | OrderTemplateParam
  | InvoiceTemplateParam
  | PaymentTemplateParam;
}
interface OrderTemplateParam {
  order: any;
  branch: Branch;
  customer: Customer;
  storage?: Storage;
  tableCompletedStorage?: PaidItem[];
  tableCompletedChargeItem?: PaidItem[];
  tableCompletedPaymentPrepaidWallet?: PaidItem[];
  tablePartialCompletedStorage?: PaidItem[];
  tablePartialCompletedChargeItem?: PaidItem[];
  tablePendingStorage?: PaidItem[];
  tablePendingChargeItem?: PaidItem[];
  tablePendingPaymentPrepaidWallet?: PaidItem[];
  tableCompletedRefundStorage?: PaidItem[];
  tableCompletedRefundChargeItem?: PaidItem[];
  tablePartialCompletedRefundStorage?: PaidItem[];
  tablePartialCompletedRefundChargeItem?: PaidItem[];
  tablePendingRefundStorage?: PaidItem[];
  tablePendingRefundChargeItem?: PaidItem[];
  tableVoidStorage?: PaidItem[];
  tableVoidChargeItem?: PaidItem[];
  tableVoidPaymentPrepaidWallet?: PaidItem[];
  tableVoidRefundStorage?: PaidItem[];
  tableVoidRefundChargeItem?: PaidItem[];
  tableCancelledStorage?: PaidItem[];
  tableCancelledChargeItem?: PaidItem[];
  tableCancelledPaymentPrepaidWallet?: PaidItem[];
  tableCancelledRefundStorage?: PaidItem[];
  tableCancelledRefundChargeItem?: PaidItem[];
  tableOtherTransaction?: Transaction[];
  tableTransactionDetails?: Transaction[];
  totalPrice?: PaidItem[];
  totalRefundPrice?: PaidItem[];
  totalNetPrice?: PaidItem[];
  totalCustomerNeedToPay?: string;
  totalCustomerOrderPaidAndPrepaid?: string;
  totalCustomerOrderPaid?: string;
  totalCustomerPrepaid?: PaidItem[];
  totalWalletPendingPayment?: PaidItem[];
  totalWalletPaidPayment?: PaidItem[];
  totalAllNonCancelledNonVoidNonWalletNonPrepaidPaymentInvoiceBillingItem?: PaidItem[];
}

interface InvoiceTemplateParam {
  // order: any;
  invoice: any;
  branch: Branch;
  customer: Customer;
  storage?: Storage;
  walletItems?: PaidItem[];
  paidItems?: PaidItem[];
  transactions?: Transaction[];
  netTotalPrice: string;
  refundTotalPrice: string;
  needToPay: string;
  willPay: string;
  prepaidToWallet: string;
  transactionWalletPaid: string;
  transactionAlreadyPaid: string;
}

interface PaymentTemplateParam {
  // order: any;
  payment: any;
  invoice: any;
  branch: Branch;
  customer: Customer;
  storage?: Storage;
  walletItems?: PaidItem[];
  paidItems?: PaidItem[];
  transactions?: Transaction[];
  netTotalPrice: string;
  refundTotalPrice: string;
  needToPay: string;
  willPay: string;
  prepaidToWallet: string;
  transactionWalletPaid: string;
  transactionAlreadyPaid: string;
}

interface ContractTemplateParam {
  contract: any;
  branch: Branch;
  customer: Customer;
  masterContract: any;
  storage?: Storage;
}

class BoxappTemplateBackend extends BoxappBackend {
  /**
  /**
   * 
   * @param {*} error 
   * @returns 
   */
  gen = async (param: GenTemplateParam): Promise<Data> => {
    try {
      const { data, status } = await axios.post<ResponseResult>(
        `${this.baseURL}/templates/gen`,
        param,
        {
          headers: {
            Accept: "text/html",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          validateStatus: (status: any) => {
            return status ? true : false;
          },
        }
      );
      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  };
  genPreview = async (param: {
    language: string;
    customer_id?: string;
    template_type?: string;
    order_type?: string;
    order_id?: string;
    branch_id?: string;
    invoice_id?: string;
    storage_id?: string;
    payment_id?: string;
    contract_id?: string;
    file_name?: string;
    transaction_id?: string;
  }): Promise<any> => {
    try {
      const response = await axios.post<ResponseResult>(
        `${process.env.REACT_APP_PDF_SERVER}/templates/gen-preview`,
        param,
        {
          headers: {
            Accept: "application/pdf",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          responseType: "arraybuffer",
          validateStatus: function (status: any) {
            return status ? true : false;
          },
        }
      );
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  };
  genPdf = async (id: string, param: any): Promise<any> => {
    try {
      const response = await axios.post<ResponseResult>(
        `${this.baseURL}/templates/${id}/gen-pdf`,
        param,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          responseType: "arraybuffer",
          validateStatus: function (status: any) {
            return status ? true : false;
          },
        }
      );
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  genPdfFromUrl = async (param: any): Promise<any> => {
    try {
      const response = await axios.post<ResponseResult>(
        `${this.baseURL}/pdf/gen-pdf-from-url`,
        param,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          responseType: "arraybuffer",
          validateStatus: function (status: any) {
            return status ? true : false;
          },
        }
      );
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  };
  createTemplate = async (param: any): Promise<Data> => {

    try {
      return this.post(`${this.baseURL}/templates`, param);
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  getTemplates = async (filter: any): Promise<Data> => {
    try {
      return this.get(`${this.baseURL}/templates`, filter);
    } catch (err) {
      throw err;
    }
  };
  getTemplate = async (id: string): Promise<Data> => {
    try {
      return this.get(`${this.baseURL}/templates/${id}`);
    } catch (err) {
      throw err;
    }
  };

  updateTemplate = async (id: string, template: any): Promise<Data> => {
    try {
      return this.put(`${this.baseURL}/templates/${id}`, template);
    } catch (err) {
      throw err;
    }
  };
}

let _BoxappTemplateBackend: BoxappTemplateBackend | null = null;

/**
 * Returns the firebase backend
 */
const getBoxappTemplateBackend = () => {
  if (!_BoxappTemplateBackend) {
    _BoxappTemplateBackend = new BoxappTemplateBackend();
  }
  return _BoxappTemplateBackend;
};

export { getBoxappTemplateBackend };
