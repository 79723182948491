import axios from "axios";
import { NavLink } from "react-router-dom";
import { BoxappBackend } from "./boxapp_helper";
import { Data, ResponseResult } from "../api/reponseResult";

class BoxappAuthBackend extends BoxappBackend {
  permissions: string[] = [];
  mfaQRCode = async (id: string): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/accounts/${id}/MFA/GOOGLE_AUTHENTICATOR`);
    } catch (err) {
      throw err;
    }
  };
  mfaLogin = async (token: string, passcode: string): Promise<Data> => {
    try {
      return await this.post(`${this.baseURL}/accounts/mfa/verify`, { token: token, passcode: passcode });
    } catch (err) {
      throw err;
    }
  };

  exchangeToken = async (token: string): Promise<Data> => {
    try {
      return await this.post(`${this.baseURL}/auth/token`, { token: token });
    } catch (err) {
      throw err;
    }
  };
  /**
   * Login user with given details
   */
  loginUser = async (username: string, password: string): Promise<Data> => {
    try {
      return await this.post(`${this.baseURL}/auth/login`, { username: username, password: password });
    } catch (err) {
      throw err;
    }
  };

  /**
   * Logout the user
   */
  logout = (): void => {
    localStorage.clear();
    return;
  };

  /**
   * Returns the authenticated user
   */

  getPermissions = (): Promise<any> => {
    return { SUPER: true } as any;
  };
  getAuthenticatedUser = async (): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/auth`);
    } catch (err) {
      throw err;
    }
  };

  getAuthPermissions = async (): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/auth/permissions`);
    } catch (err) {
      throw err;
    }
  };
  changePassword = async (id: string, changes: any): Promise<Data> => {
    try {
      return await this.put(`${this.baseURL}/account/${id}/password/change`, changes);
    } catch (err) {
      throw err;
    }
  };
}

let _BoxappAuthBackend: BoxappAuthBackend | null = null;

/**
 * Returns the firebase backend
 */
const getBoxappAuthBackend = () => {
  if (!_BoxappAuthBackend) {
    _BoxappAuthBackend = new BoxappAuthBackend();
  }
  return _BoxappAuthBackend;
};

export { getBoxappAuthBackend };
